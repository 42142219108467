import { httpservice } from './httpService';
import { constants } from '../constants/constant';
function pageName(pageName) {
  switch (pageName) {
    case 'reset-password':
      return constants.PAGES.RESET_PASSWORD;
    case 'user-activation':
      return constants.PAGES.USER_ACTIVATION;
    case '404':
      return constants.PAGES.NOT_FOUND;
    default:
      return 'default route';
  }
}

export function getPageContent(name) {
  let pgName = pageName(name);
  return httpservice()
    .get(`${constants.API_URL}${pgName}`, { params: { language: constants.LOCALIZATION_LANGUAGE } })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
