<template>
  <div>
    <b-modal
      ref="user-validate-modal"
      id="modal"
      modal-class="user-validate-modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center" v-if="isloading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <p class="my-4 text-center">{{labels.VerifyingEIDPleaseWait}}</p>
    </b-modal>
  </div>
</template>

<script>
import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
export default {
  name: "eidValidation",
  mixins: [vcm, vvm],
  data() {
    return {
      isloading: false,
      labels: {
        VerifyingEIDPleaseWait: this.get_scope_translation("VerifyingEIDPleaseWait", "label"),
      },
      data: {},
    };
  },

  async created() {},

  async mounted() {
    // if this is in an iframe, try to break out of it
    if (window.top != window) {
        window.top.location.href = window.location.href;
        return
    }

    this.isloading = true;
    this.showModal();
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    this.$root.data_access.setIdentifier("user.signin.austria_id");
    this.$root.data_access.setReceiver("component", this);
    this.$root.data_access.setRequestBody({
      Code: code,
    });
    this.$root.data_access.call("signin_austria_id");
  },
  methods: {
    showModal() {
      this.$refs["user-validate-modal"].show();
    },

    receive: function (dataObject) {
      console.log(dataObject);
      if (dataObject) {
        switch (dataObject.identifier) {
          case "user.signin.austria_id":
            if (dataObject.data) {
              var errorUnauthorized = true;
              if (dataObject.data.response) {
                var userIntitialized = this.$root.set_system_user(
                  "sign.in",
                  dataObject.data.response,
                  this.$root.get_user_credentials("User", "Confirmed", "", "")
                );
                if (userIntitialized) {
                  errorUnauthorized = false;
                  this.$root.goto("startpage");
                }
              }

              if (errorUnauthorized) {
                window.alert(this.errors.SignInFailed);
              }
            }
            break;
        }
      }
    },
  },
};
</script>

<style lang="css">
.user-validate-modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
}
.user-validate-modal > div {
  width: 80% !important;
}

div#modal .modal-dialog.modal-md .modal-content > header {
  border-bottom: 0 !important;
}
.spinner-border {
  color: #c51645 !important;
}
</style>
