<template>
    <h1 v-if="title !==undefined">{{ title }}</h1>
    <h1 v-else >{{ this.$root.get_translation('Route',this.$route.name,'label') }}</h1>
</template>

<script>
export default {
  name: 'ViewTitle',
  props: {    
      mode: String,
      title: String
  },
  data() {
    return {      

    };
  },
  computed: {  
  },
  methods: {
   
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";



</style>
