export const Interseptor = {
  requestHandler(request) {
    return request;
  },
  errorHandler(error) {
    console.log('...error', error);
    if (error && error.response.status === 401) {
      localStorage.clear();
      window.location = '/';
      return {};
    } else {
      return Promise.reject({ ...error });
    }
  },
  successHandler(response) {
    if (response && response.status === 401) {
      localStorage.clear();
      window.location = '/';
      return {};
    } else {
      return response;
    }
  },
};
