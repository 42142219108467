var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vcs-triangles",class:_vm.mode},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-5"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-6"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-7"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-8"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-9"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-10"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vct-element-case"},[_c('div',{staticClass:"vct-element set-11"})])
}]

export { render, staticRenderFns }