import { initialize } from '@/services/initializeService';
export default {
  state: {
    source: JSON.parse(localStorage.getItem('source')) ? JSON.parse(localStorage.getItem('source')) : {},
    sourceAddressList: JSON.parse(localStorage.getItem('source_address_list'))
      ? JSON.parse(localStorage.getItem('source_address_list'))
      : [],
    metaLinks: JSON.parse(localStorage.getItem('meta_links')) ? JSON.parse(localStorage.getItem('meta_links')) : [],
    sourcePartnerList: JSON.parse(localStorage.getItem('source_partner_list'))
      ? JSON.parse(localStorage.getItem('source_partner_list'))
      : [],
    sourceSelectionList: JSON.parse(localStorage.getItem('source_selection_list'))
      ? JSON.parse(localStorage.getItem('source_selection_list'))
      : [],
    sourceSelectionOptions: JSON.parse(localStorage.getItem('source_selection_options'))
      ? JSON.parse(localStorage.getItem('source_selection_options'))
      : [],
    applicationTitle: localStorage.getItem('vcl_base_application_title')
      ? localStorage.getItem('vcl_base_application_title')
      : null,
  },
  actions: {
    async getInitialize({ commit }) {
      initialize()
        .then((res) => {
          commit('SET_INITIALIZE_DATA', res.response);
        })
        .catch((error) => console.log('error', error));
    },
  },
  mutations: {
    SET_APPLICATION_TITLE(state, payload) {
      state.applicationTitle = payload;
    },
    SET_INITIALIZE_DATA(state, payload) {
      state.source = payload.Source;
      state.sourceAddressList = payload.SourceAddressList;
      state.metaLinks = payload.SourceFooterLinkList;
      state.sourcePartnerList = payload.SourcePartnerList;
      state.sourceSelectionList = payload.SourceSelectionList;
      localStorage.setItem('source', JSON.stringify(payload.Source));
      localStorage.setItem('source_address_list', JSON.stringify(payload.SourceAddressList));
      localStorage.setItem('meta_links', JSON.stringify(payload.SourceFooterLinkList));
      localStorage.setItem('source_partner_list', JSON.stringify(payload.SourcePartnerList));
      localStorage.setItem('source_selection_list', JSON.stringify(payload.SourceSelectionList));
      let sourceSelectionOptions =
        payload.SourcePartnerList &&
        payload.SourcePartnerList.map((source) => {
          let obj = { value: source.UUID, text: source.Title };
          return obj;
        });
      state.sourceSelectionOptions = sourceSelectionOptions;
      localStorage.setItem('source_selection_options', JSON.stringify(sourceSelectionOptions));
    },
  },
};
