// eslint-disable-next-line
import Vue from 'vue'

export const vvm = {
    data () {
        return {     
            statebar_refresh: 0,
            alerts_refresh: 0
        }
    },
    props: {   
        
    },
    mounted: function () {
        this.$root.reset_path()
    },
    methods: {                
        update_statebar: function() {
            this.statebar_refresh += 1
        },
        update_alerts: function() {
            this.alerts_refresh += 1
        },
        add_alert: function(parName,parDescription,parIcon) {
            this.$root.add_alert(parName,parDescription,parIcon)       
            this.update_alerts()     
        }   
    },
    watch: {
        $route: {
            immediate: true,
            // eslint-disable-next-line
            handler(to, from) {
                this.$root.set_document_title('label','Route',to.name)
            }
        }
    }
}
