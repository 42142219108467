const vcl = {
  // eslint-disable-next-line
  install(Vue, options) {
    Vue.vclPrefix = function() {
      return 'vcl_base_';
    },
      Vue.vclGet = function(key, dv) {
        var rv = dv;
        var value = localStorage.getItem(Vue.vclPrefix() + key);
        if (value) {
          var valueExpiration = localStorage.getItem(Vue.vclPrefix() + key + '_expiration');
          if (valueExpiration != '') {
            var valueExpirationTime = valueExpiration * 1;
            if (valueExpirationTime === 0) {
              rv = value;
            } else {
              var dt = new Date();
              if (valueExpirationTime !== null) {
                if (dt.getTime() < valueExpirationTime) {
                  rv = value;
                }
              }
            }
          }
        }
        return rv;
      }
      Vue.vclSet = function(key, value, expirationInMinutes) {
        var expiration = 0;
        if (expirationInMinutes > 0) {
          var dt = new Date();
          dt.setMinutes(dt.getMinutes() + expirationInMinutes);
          expiration = dt.getTime();
        } else {
          //
        }
        localStorage.setItem(Vue.vclPrefix() + key, value);
        localStorage.setItem(Vue.vclPrefix() + key + '_expiration', expiration);
      },

      Vue.vclUpdate = function(key, value) {
        localStorage.setItem(Vue.vclPrefix() + key, value);
      },
      Vue.vclCapitalize = function(words) {
        var rv = '';
        if (words) {
          rv = words.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          });
        }
        return rv;
      },
      Vue.vclGetPasswordComplexityLevel = function(password, password_minimum_length) {
        var rv = 0;

        if (password.length >= password_minimum_length) {
          rv++;
        }

        var regex = new Array();
        regex.push('[A-Z]');
        regex.push('[a-z]');
        regex.push('[0-9]');
        regex.push('[$@$!%*#?&]');

        for (var i = 0; i < regex.length; i++) {
          if (new RegExp(regex[i]).test(password)) {
            rv++;
          }
        }

        if (password.length < password_minimum_length) {
          rv = 0;
        }

        return rv;
      },
      Vue.vclFormattedDate = function(dateString, format) {
        var rv = '';
        var dt = null;
        if (dateString !== '') {
          dt = format.includes('.')
            ? new Date(dateString)
            : new Date(dateString.split(/[. :T]/)[2], dateString.split(/[. :T]/)[1] - 1, dateString.split(/[. :T]/)[0]);
        } else {
          dt = new Date();
        }

        if (dt) {
          rv = format;
          var year = dt.getFullYear();
          var month = dt.getMonth() + 1;
          var day = dt.getDate();
          var hours = dt.getHours();
          var minutes = dt.getMinutes();
          var seconds = dt.getSeconds();

          if (month < 10) {
            month = '0' + month;
          }
          if (day < 10) {
            day = '0' + day;
          }
          if (hours < 10) {
            hours = '0' + hours;
          }
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          if (seconds < 10) {
            seconds = '0' + seconds;
          }

          rv = rv.replace('YYYY', year);
          rv = rv.replace('MM', month);
          rv = rv.replace('DD', day);
          rv = rv.replace('HH', hours);
          rv = rv.replace('II', minutes);
          rv = rv.replace('SS', seconds);
        }
        return rv;
      },
      Vue.vclDeploy = function(label, content) {
        if (Vue.vclGet('system_debug_console', '') == 'on') {
          console.log(label, content);
        }
      },
      Vue.vclDeployInfo = function(label, content) {
        if (Vue.vclGet('system_debug_console', '') == 'on') {
          console.info(label, content);
        }
      },
    
      Vue.vclAppend = function(text, appendText, separator) {
        var rv = text;
        if (appendText != '') {
          if (separator != '') {
            rv += separator;
          }
          rv += appendText;
        }
        return rv;
      },
      Vue.vclValidate = function(mode, content) {
        var rv = false;
        switch (mode.toLowerCase()) {
          case 'email':
            // eslint-disable-next-line
            var exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            rv = exp.test(content);
            break;
        }
        return rv;
      };
  },
};

export default vcl;
