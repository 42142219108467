<template>
  <div
    role="region"
    aria-label="Menu"
    class="vcs-vertical-nav"
    v-bind:class="this.$root.get_menu_state"
  >
    <div class="pl-2 mb-3 vcs-banner">
      <div class="image-space"></div>
      <router-link to="/">
        <img
          v-bind:src="get_asset_image('logo_dk_mark.png')"
          :alt="labels.PlattformLogoSmall"
          class="int"
        />
        <img
          v-bind:src="get_asset_image('logo_dk.png')"
          :alt="labels.PlattformLogo"
          class="on"
        />
      </router-link>
    </div>

    <div role="navigation" aria-label="Main">
      <MenuNavigation></MenuNavigation>
    </div>

    <div
      class="vcs-middle"
      v-bind:class="get_menu_switch()"
      @click="toggleClass()"
    >
      <div v-if="this.$root.get_menu_state == 'on'" class="vcs-left">
        <b-icon-chevron-left></b-icon-chevron-left>
      </div>
      <div v-if="this.$root.get_menu_state == 'int'" class="vcs-right">
        <b-icon-chevron-right></b-icon-chevron-right>
      </div>
    </div>
  </div>
</template>
<script>
import MenuNavigation from "@/structure/MenuNavigation.vue";
import { vcm } from "@/mixins/vcm";

export default {
  mixins: [vcm],
  name: "Menu",
  components: {
    MenuNavigation,
  },
  props: {},
  data() {
    return {
      labels: {
        PlattformLogo: this.get_translation("App", "PlattformLogo", "label"),
        PlattformLogoSmall: this.get_translation(
          "App",
          "PlattformLogoSmall",
          "label"
        ),
      },
    };
  },
  computed: {},
  methods: {
    get_menu_switch: function () {
      var rv = "";
      rv += this.$root.get_menu_state + " ";
      rv += this.$root.get_menu_mode_button_layout + " ";
      return rv;
    },
    toggleClass: function () {
      switch (this.$root.menu_state) {
        case "off":
          this.$root.menu_state = "int";
          break;

        case "int":
          this.$root.menu_state = "on";
          break;

        case "on":
          this.$root.menu_state = "int";
          break;
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-banner {
  height: 11%;
  background-color: $highlightColor;
}
.vcs-vertical-nav .vcs-banner .image-space {
  height: 21%;
}
.vcs-vertical-nav .vcs-banner img {
  height: auto;
  width: 70%;
  margin-left: 0.45rem;
}
.vcs-vertical-nav .vcs-banner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vcs-vertical-nav.int .vcs-banner img.int {
  display: block;
}
.vcs-vertical-nav.int .vcs-banner img.on {
  display: none;
}

.vcs-vertical-nav.on .vcs-banner img.int {
  display: none;
}
.vcs-vertical-nav.on .vcs-banner img.on {
  display: block;
}

.vcs-nav-item {
  margin-left: 24px;
  color: $appFontColor;
}
.vcs-nav-item span {
  padding-right: 9px;
}
.vcs-nav-item a {
  color: $appFontColor;
}
.vcs-nav-item a:hover {
  color: $appFontColor;
  text-decoration: none;
}

@media only screen and (max-height: 768px) {
  .vcs-banner {
    height: 17%;
    background-color: $highlightColor;
  }
}

.vcs-middle {
  position: absolute;
  top: 50%;
  margin-top: -24px;
  right: -14px;
  transition: all 0.4s;
  cursor: pointer;
  width: 16px;
  height: 48px;
  background-color: $menuClipColor;
  color: #000000;
}

.vcs-middle:before {
  content: "";
  position: absolute;
  top: -16px;
  width: 0;
  height: 0;
  border-bottom: 16px solid $menuClipColor;
  border-right: 16px solid transparent;
}
.vcs-middle:after {
  content: "";
  position: absolute;
  bottom: -16px;
  width: 0;
  height: 0;
  border-top: 16px solid $menuClipColor;
  border-right: 16px solid transparent;
}

.vcs-left {
  color: $menuBGColor;
  font-weight: 700;
}
.vcs-right {
  color: $menuBGColor;
  font-weight: 700;
}

.vcs-middle.off .vcs-left {
  display: none;
}
.vcs-middle.off .vcs-right {
  display: block;
  padding-top: 12px;
}
.vcs-middle.circle.off .vcs-right {
  display: block;
  padding-top: 2px;
  padding-left: 4px;
}

.vcs-middle.int .vcs-left {
  display: none;
}
.vcs-middle.int .vcs-right {
  display: block;
  padding-top: 12px;
}
.vcs-middle.circle.int .vcs-right {
  display: block;
  padding-top: 2px;
  padding-left: 6px;
}

.vcs-middle.on .vcs-left {
  display: block;
  padding-top: 12px;
}
.vcs-middle.circle.on .vcs-left {
  display: block;
  padding-top: 2px;
  padding-left: 5px;
}
.vcs-middle.on .vcs-right {
  display: none;
}

.vcs-middle.circle {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.vcs-middle.circle:before {
  display: none;
}
.vcs-middle.circle:after {
  display: none;
}

.vcs-vertical-nav.int .nav-item .nav-link {
  padding: 0.5rem 0.8rem 0.5rem 1rem;
}

.vcs-vertical-nav {
  min-width: 15rem;
  width: 15rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.4s;
  background-color: $menuBGColor;
  z-index: 10000;
}
.vcs-vertical-navDIS {
  box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.2);
}
.vcs-vertical-nav.int {
  min-width: 4.2rem;
  width: 4.2rem;
}
.vcs-vertical-nav .nav-item .nav-link {
  white-space: nowrap;
}
.vcs-vertical-nav.int .nav-item .nav-link span {
  display: none;
}

.vcs-vertical-nav .vcs-bottom {
  position: absolute;
  bottom: 24px;
  left: 24px;
  font-size: 12px;
  line-height: 20px;
}
.vcs-vertical-nav.int .vcs-bottom {
  display: none;
}
.vcs-vertical-nav.int .media-body {
  display: none;
}
.vcs-vertical-nav.int .media {
  width: 48px;
}
.vcs-vertical-nav.off {
  margin-left: -15rem;
}

.vcs-vertical-nav .vcs-bar:hover .vcs-middle {
  opacity: 0;
}

.vcs-bar {
  border: 2px dashed #cc0000;
}
</style>
