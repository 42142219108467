import {deleteUserNotification,deleteUser, getUser, sendFPEmail, setupPassword, changePassword, sendActivationCode } from '@/services/settingService';
import { constants } from '../../constants/constant';
import moment from 'moment';
export default {
  state: {
    person: {},
    token:'',
    Address: {},
    MainAddress: {},
    Addresses: [],
    UserInformation: [],
    MainBankAccount: {},
    MessageSetting: {
      ActiveAlternateEmail: false,
      AlternateEMail: '',
      GetMessages: false,
        GetNotification:false
    },
    PrincipleAddress:{},
    ResidenceAddress:{},
    UserSettings: {},
    locationData: [],
    locationFields: [],
    CountryList: [],
    LocationLabelsObj: {},
    countryObj: {},
    Salutation: { 1: 'Frau', 2: 'Herr' },
    activationState: false,
    NotFullyRegisteredUser:true,
    RegisterKeySet: {},
    UserSignIn:{},
    
  },
  getters: {
    getActivationState: function(state) {
      return state.activationState;
    },
    getCurrentFields: function(state) {
      var rv = state.locationFields.filter(function(obj) {
        return (
          obj.key != 'Emblem' &&
          obj.key != 'StreetNumber' &&
          obj.key != 'ValidFrom' &&
          obj.key != 'ValidTo' &&
          obj.key != 'TypeName'
        );
      });
      rv.push({
        key: 'ValidFrom',
        class: 'validfrom',
        label: state.LocationLabelsObj['ValidSince'],
      });
      rv.push({ key: 'ValidTo', class: 'validto', label: '' });
      return rv;
    },
    getPreviousFields: function(state) {
      var rv = state.locationFields.filter(function(obj) {
        return obj.key != 'StreetNumber' && obj.key != 'Emblem' && obj.key != 'TypeName';
      });
      return rv;
    },
    getCurrentResidences: function(state) {
      return state.locationData.filter(function(obj) {
        let currentDate = moment().format('YYYY-MM-DD');
        let then = obj.ValidTo && moment(new Date(obj.ValidTo)).format('YYYY-MM-DD');
        if (moment(currentDate).isBefore(then) && obj.TypeName === 'Residence') {
          delete obj.ValidTo;
          return obj;         
        }
      });
    },
    getPreviousResidences: function(state) {
      return state.locationData.filter(function(obj) {
        let currentDate = moment().format('YYYY-MM-DD');
        let then = obj.ValidTo && moment(new Date(obj.ValidTo)).format('YYYY-MM-DD');
        if (moment(currentDate).isAfter(then) && obj.TypeName === 'Residence') {
          return obj;
        }
      });
    },
    getAddress: function(state) {
      return state.Address;
    },
    getCountryList: function(state) {
      return state.CountryList;
    },
    countryObj: function(state) {
      for (let country of state.CountryList) {
        state.countryObj[country.value] = country.text;
      }
      return state.countryObj;
    },
  },
  actions: {
    async getUserAction({ commit }) {
      getUser().then((res) => {
        commit('SET_USER_SET', res.response);
      });
    },
    async getDeleteUserNotification() {
      return deleteUserNotification().then((response) => response);
    },
    async getDeleteUser() {
      return deleteUser().then((response) => response);
    },
    // eslint-disable-next-line
    async sendForgotPasswordEmail({ commit }, { Email }) {
      return sendFPEmail({ Email }).then((response) => {
        return response;
      });
    },

    // eslint-disable-next-line
    async setupNewPassword({ commit }, data) {
      return setupPassword(data).then((response) => {
        return response;
      });
    },
    // eslint-disable-next-line
    async resetPasswordAction({ commit }, { OldPassword, NewPassword }) {
      return changePassword({ OldPassword, NewPassword }).then((response) => response);
    },

    // activate code
    // eslint-disable-next-line
    async activateCodeAction({ commit }, { TypeName, Code }) {
      return sendActivationCode({ TypeName, Code }).then((response) => response);
    },
  },
  mutations: {
    SET_ACTIVATION_STATE(state, payload) {
      state.activationState = payload;
    },
    SET_USER_SET(state, payload) {
      state.person = payload.User;
      if(state.person.SourceUUID === "00000000-0000-0000-0000-000000000000"){
        state.person.SourceUUID = "";
      }
      if(state.person.Salutation === 0){
        state.person.Salutation="";
      }
      state.activationState = payload?.User?.StateName === 'Complete' ? true : false;
      let currentDate = moment().format('YYYY-MM-DD');
      let principleAddress = payload.Addresses.filter(
        (obj) => obj.UserAddressTypeName === 'PrincipalResidence' && obj.TypeName === 'Residence'
      );
      let principleaddressArray =payload.Addresses.filter(
        (obj) => obj.UserAddressTypeName === 'PrincipalResidence' && obj.TypeName === 'Residence' && moment(currentDate).isSameOrBefore(obj.ValidTo)
      ); 
      state.PrincipleAddress= principleaddressArray.length? principleaddressArray[0] : null;
      let ResidentAddress= payload.Addresses.filter(
        (obj) => obj.UserAddressTypeName === 'Residence' && obj.TypeName === 'Residence' && moment(currentDate).isSameOrBefore(obj.ValidTo)
      );
      state.token= payload.User.token;
      state.ResidenceAddress = ResidentAddress.length? ResidentAddress[0] : null;
      state.MainAddress = principleAddress[0] ? principleAddress[0] : payload.User.MainAddress;
      state.MainBankAccount = payload.MainBankAccount;
      if (payload.User.MainAddress.TypeName === 'Delivery') {
        state.Address = payload.User.MainAddress;
      } else {
        state.Address={
          StreetNumber : '',
          Street : '',
          ApartmentNumber : '',
          Staircase : '',
          City :'',
          PostalCode : '',
          Country : '',
          Email :'',
          Phone : '',
          TypeName : '',
        }
     }
      state.Addresses = payload.Addresses;
      state.UserInformation = payload.UserInformation;
      state.CountryList = payload.CountryList.map((country) => {
      let obj = { value: country.ID, text: country.NameDE };
        return obj;
      });
      state.MessageSetting = {
        ActiveAlternateEmail: payload.User.MessageSetting.ActiveAlternateEmail,
        AlternateEMail: payload.User.MessageSetting.AlternateEMail,
        GetMessages:  payload.User.MessageSetting.GetMessages,
        GetNotification:  payload.User.MessageSetting.GetNotification,
      }
      state.UserSignIn=payload.User.UserSignIn;
      state.RegisterKeySet = payload.RegisterKeySet[0]; 
      state.NotFullyRegisteredUser=state.RegisterKeySet.RegisterKeyTypeName !== '' && state.RegisterKeySet.RegisterKey !== '' && state.UserSignIn.Email === '';
      if(!payload.User.MessageSetting.AlternateEMail){
          localStorage.setItem('getalternate', false);
      }
      else{
         localStorage.setItem('alternate',payload.User.MessageSetting.AlternateEMail)
         localStorage.setItem('getalternate', true);
      }
      // this is for locationData
      const FiledLabels = JSON.parse(
        localStorage.getItem('form_field_labels_' + `${constants.LOCALIZATION_LANGUAGE}` + '_' + 'LocationData')
      );
      for (let label of FiledLabels.Label) {
        state.LocationLabelsObj[label.Name] = label.Label;
      }

      let locationAddressData = [];
      for (let adObj of payload.Addresses) {
        let emblem;
        if (adObj.Source) {
          emblem = adObj.Source.Emblem;
        }
        var entry = {
          Type: adObj.UserAddressTypeName,
          TypeName: adObj.TypeName,
          Street: adObj.Street,
          StreetNumber: adObj.StreetNumber,
          ApartmentNumber: adObj.ApartmentNumber,
          Staircase: adObj.Staircase,
          PostalCode: adObj.PostalCode,
          City: adObj.City,
          ValidFrom: adObj.ValidFrom,
          ValidTo: adObj.ValidTo,
          Emblem: emblem,
        };
        locationAddressData.push(entry);
      }
      var AddressKeys = locationAddressData && Object.keys(locationAddressData[0]);
      let fields = [];
      for (let key of AddressKeys) {
        fields.push({
          key: key,
          class: key.toLowerCase(),
          label: state.LocationLabelsObj[key],
        });
      }
      state.locationFields = fields;
      state.locationData = locationAddressData;
    },
  },
};