<template>
  <div id="app" class="h-100 w-100 m-0 p-0">
    <!-- <keep-alive> -->
      <router-view :key="$route.path" />  
    <!-- </keep-alive>   -->
    <Alerts :key="alerts_refresh" position="bottom-right" />
  </div>
</template>

<script>
import Vue from 'vue'
import Alerts from '@/components/Alerts.vue'
import { vvm } from '@/mixins/vvm'
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

export default {
    mixins: [vvm],
    name: 'App',
    components: { Alerts }
};
</script>


<style lang="scss">
@import "@/assets/_app.scss";
</style>
