import Vue from 'vue';
import VueRouter from 'vue-router';
import Startpage from '../views/Startpage.vue';
import UserValidation from '../views/UserValidation.vue';
import EIDValidation from '../views/EIDValidation.vue';
import NotFound from '../views/NotFound.vue';

import vcl from '../vcl';
import qs from 'qs';
import { getUser } from '@/services/settingService';
Vue.use(vcl);

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'startpage',
    component: Startpage,
  },
  {
    path: '/verfahren',
    name: 'proceedings',
    component: () => import('../views/Proceedings.vue'),
  },
  {
    path: '/konto',
    name: 'accounting',
    component: () => import('../views/Accounting.vue'),
  },
  {
    path: '/validateuser',
    name: 'Validateuser',
    component: UserValidation,
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/meldedaten',
    name: 'locationdata',
    component: () => import('../views/LocationData.vue'),
  },
  {
    path: '/formulare',
    name: 'forms',
    component: () => import('../views/Forms.vue'),
  },
  {
    path: "/nachrichten",
    name: "message",
    component: () => import("../views/Message.vue"),
  },
  {
    path: "/archiv",
    name: "archiv",
    component: () => import("../views/Message.vue"),
  },
  {
    path: "/benutzerdaten",
    name: "userdata",
    component: () => import("../views/UserData.vue"),
  },
  {
    path: "/benutzerloeschen",
    name: "deleteuser",
    component: () => import("../views/DeleteUser.vue"),
  },
  {
    path: "/loeschenbestaetigt",
    name: "thankyou",
    component: () => import("../views/ThankYou.vue"),
  },
  {
    path: '/anmeldung',
    name: 'signin',
    component: () => import('../views/SignIn.vue'),
    // props: route => ({
    //   queryType: route.query.status,
    //   uuid: route.query.uuid,
    // }),
  },
  {
    path: '/egp',
    name: 'signin-egp',
    component: EIDValidation,
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/passwortvergessen',
    name: 'forgotpassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/passwortbestaetigen',
    name: 'confirmpassword',
    component: () => import('../views/ConfirmForgotPassword.vue'),
  },
  {
    path: '/passwortaendern',
    name: 'passwortandern',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('../views/EmailValidate.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/registrierung',
    name: 'signup',
    component: () => import('../views/SignUp.vue'),
  },
  {
    path: '/einstellungen',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
  },
  {
    path: '/info',
    name: 'systeminfo',
    component: () => import('../views/SystemInfo.vue'),
  },
  {
    path: '/Impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue'),
    },
    {
        path: '/dsgvo',
        name: 'Datenschutz',
        component: () => import('../views/Privacy.vue'),
    },
  {
    path: '/AGB',
    name: 'AGB',
    component: () => import('../views/AGB.vue'),
  },
  {
    path: '/Feedback',
    name: 'Feedback',
    component: () => import('../views/Feedback.vue'),
  },
  {
    path: '/aktivierung',
    name: 'Activation',
    component: () => import('../views/AccountActivation.vue')
  },
  {
    path: '/signinEID',
    name: 'signinEID',
    component: () => import('../views/SignInEID.vue'),
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },

  // meta infos

  // employee views!!!!?? **************************************************** <<< UP NEXT <<<
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  stringifyQuery: (query) => {
    let result = qs.stringify(query, { format: 'RFC1738' });
    return result ? '?' + result : '';
  },
  routes,
});

router.beforeEach((to, from, next) => {
  //var stateName = Vue.vclGet('user_db_stateName','User');
  var continueRoute = true;
  var currentstateName = Vue.vclGet('user_db_stateName', '');
  var systemState = Vue.vclGet('system_state', '');
  var userState = Vue.vclGet('user_state', '');
  if (userState != 'signed.in') {
    if (systemState != 'system.initialized') {
      if (
        to.name != 'signin' &&
        to.name != 'signin-egp' &&
        to.name != 'Validateuser' &&
        to.name != 'AGB' &&
        to.name != 'Feedback' &&
        to.name != 'Impressum' &&
        to.name != 'Datenschutz' &&
        to.name != 'signup' &&
        to.name != 'signinEID' &&
        to.name != 'forgotpassword' &&
        to.name != 'confirmpassword' &&
        to.name != 'verification' &&
        to.name != 'notfound' &&
        to.name != 'thankyou'
      ) {
        next('/anmeldung');
      }
    } else {
      if (
        to.name != 'signin' &&
        to.name != 'signin-egp' &&
        to.name != 'Validateuser' &&
        to.name != 'AGB' &&
        to.name != 'Feedback' &&
        to.name != 'Impressum' &&
        to.name != 'Datenschutz' &&
        to.name != 'signup' &&
        to.name != 'signinEID' &&
        to.name != 'forgotpassword' &&
        to.name != 'confirmpassword' &&
        to.name != 'verification' &&
        to.name != 'notfound' &&
        to.name != 'thankyou'
      ) {
        next('/anmeldung');
      } else {
        next();
      }
    }
  }
  else {
      // if you are already logged in, and try to access login screen another time
      if (to.name == 'signin' ||
          to.name == 'signin-egp') {
          next('/'); // redirect to start page
      }
       else if ((to.name === 'accounting' ||
       to.name === 'message' || 
       to.name === 'archiv' || 
       to.name === 'locationdata') && currentstateName !== 'Complete') {
        var currentRoute= localStorage.getItem("route");
          next(currentRoute);
      }
       else {
          var stateName = "";
          getUser().then((result) => {
              if (result.responseCode === '200' && result.responseMessage === 'User data') {
                  stateName = result.response.User.StateName;
                  console.log(stateName);
                  localStorage.setItem("route", to.path);
                  if (to.name === 'Activation' && stateName !== 'CodeEntry') {
                      if (to.name === 'Activation' && stateName !== 'CodeDispatched') {
                          next('/');
                      }
                  }
                  else {
                      next();
                  }
              }
          });
      }
  }

  if (continueRoute) {
      next();
  }
});

export default router;
