// eslint-disable-next-line
import Vue from 'vue';

export const vcm = {
  data() {
    return {
      password_complexity_level: 0,
      public_path: process.env.BASE_URL,
      passwordType: 'password',
      error_collection: [],
      isSuccess: false,
    };
  },
  props: {},
  mounted: function() {},
  computed: {
    get_error_messages: function() {
      var rv = '';
      var iv = '';

      for (var i = 0; i < this.error_collection.length; i++) {
        var msgObj = this.error_collection[i];
        if (msgObj) {
          iv += '<li>' + msgObj.message + '</li>';
        }
      }

      if (iv != '') {
        this.isSuccess ?
        rv += '<ul class=vgs-success-message>' + iv + '</ul>'
        :
        rv += '<ul class=vgs-error-alert>' + iv + '</ul>'
      }

      return rv;
    },
  },
  methods: {
    get_upper_case(uppercaseText) {
      return uppercaseText.toUpperCase();
    },
    add_error_message(messageText, isSuccess) {
      this.error_collection = [{ message: messageText }];
      this.isSuccess = isSuccess;
    },
    get_language: function() {
      return this.$root.config.localization.language;
    },
    get_asset_image: function(name) {
      return require('@/assets/' + name);
    },
    get_translation: function(nameSpace, name, field) {
      var rv = '';
      rv = this.$root.get_translation(nameSpace, name, field);
      return rv;
    },
    contentvuecomponent: function(code){
      return Vue.compile(code);
    },
    get_type_translation: function(nameSpace, name, field) {
      var rv = '';
      rv = this.$root.get_type_translation(nameSpace, name, field);
      return rv;
    },
    get_scope_translation: function(name, field) {
      var rv = '';
      rv = this.$root.get_translation(this.$options.name, name, field);
      return rv;
    },
    get_datetime: function(dt, formatCode) {
      var rv = dt;
      var dtFormatted = new Date(dt);
      var options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      switch (formatCode.toLowerCase()) {
        case 'short':
          options = { year: 'numeric', month: 'numeric', day: 'numeric' };
          break;

        case 'long':
          options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
          break;
      }

      rv = dtFormatted.toLocaleDateString(this.$root.language_locale, options);

      return rv;
    },
    get_formatted_date(dt, format) {
      return Vue.vclFormattedDate(dt, format);
    },
    get_formatted_short_date(dt) {
      return Vue.vclFormattedDate(dt, this.$root.config.localization.language_short_date_format);
    },
    get_formatted_number(value) {
      let formatValue = value.toFixed(2).toLocaleString(this.$root.config.localization.language_locale)
      +
     ' ' +
     this.$root.config.accounting.currency_sign;
     formatValue = formatValue.replace('.',',');
     return formatValue;
    },
    get_curreny_formatted_number(value) {
      return value.toLocaleString(this.$root.config.localization.language_locale, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      });
    },
    get_copyright(mode) {
      var rv = '';
      var dtYear = new Date().getFullYear();
      var lb = this.$root.get_translation('App', 'Copyright', 'label');
      var cc = this.$root.get_system_setting('SystemLicenseHolder', '').trim();
      if (cc == '') {
        cc = this.$root.config.system.content_copyright;
      }

      switch (mode) {
        default:
          rv = lb + '&nbsp;&copy;&nbsp;' + dtYear + '&nbsp;' + cc;
          break;
      }

      return rv;
    },
    set_password_complexity_level_handler(event) {
      this.password_complexity_level = Vue.vclGetPasswordComplexityLevel(
        event.target.value,
        this.$root.config.credentials.password_minimum_length
      );
    },
    fm_password_switch() {
      if (this.passwordType == 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    set_responsive_marker(windowWidth) {
      this.$root.responsive_marker_collection = [];
      var rv = '';

      if (windowWidth >= 1600) {
        rv += 'wide';
      } else if (windowWidth >= 1400 && windowWidth < 1600) {
        rv += 'verylarge';
      } else if (windowWidth >= 1200 && windowWidth < 1400) {
        rv += 'large';
      } else if (windowWidth >= 1000 && windowWidth < 1200) {
        rv += 'normal';
      } else if (windowWidth >= 800 && windowWidth < 1000) {
        rv += 'medium';
      } else if (windowWidth >= 600 && windowWidth < 800) {
        rv += 'small';
      } else if (windowWidth >= 400 && windowWidth < 600) {
        rv += 'verysmall';
      } else if (windowWidth >= 0 && windowWidth < 400) {
        rv += 'narrow';
      } else {
        rv += '';
      }

      switch (rv) {
        case 'narrow':
        case 'verysmall':
          this.$root.menu_state_recovery = this.$root.menu_state;
          this.$root.menu_state = 'off';
          break;
        case 'small':
        case 'medium':
          this.$root.menu_state_recovery = this.$root.menu_state;
          this.$root.menu_state = 'int';
          break;
        case 'normal':
        case 'large':
        case 'verylarge':
        case 'wide':
          if (this.$root.menu_state_recovery != '') {
            this.$root.menu_state = this.$root.menu_state_recovery;
          }
          break;
      }

      switch (rv) {
        case 'narrow':
        case 'verysmall':
          this.$root.responsive_marker_collection.push('phone');
          break;
        case 'small':
        case 'medium':
          this.$root.responsive_marker_collection.push('tablet');
          break;
        case 'normal':
        case 'large':
        case 'verylarge':
        case 'wide':
          this.$root.menu_state_recovery = this.$root.menu_state;
          this.$root.menu_state = 'on';
          this.$root.responsive_marker_collection.push('desktop');
          break;
      }

      this.$root.responsive_marker_collection.push(rv.trim());
    },
    get_responsive_marker_class() {
      var rv = '';

      for (var i = 0; i < this.$root.responsive_marker_collection.length; i++) {
        rv += ' vrm-' + this.$root.responsive_marker_collection[i] + ' ';
      }

      return rv;
    },
    check_responsive_marker(mode, markerSet) {
      var rv = false;

      var pMarkerFound = markerSet.some((r) => this.$root.responsive_marker_collection.indexOf(r) >= 0);

      switch (mode.toLowerCase()) {
        case 'including':
          rv = false;
          if (pMarkerFound == true) {
            rv = true;
          }
          break;
        case 'excluding':
          rv = true;
          if (pMarkerFound == true) {
            rv = false;
          }
          break;
      }
      return rv;
    },
  },
};
