import { messages, messageDetail, messageList, readMessage } from '@/services/messageService';
import moment from 'moment';
import _ from 'lodash';

export default {
  state: {
    read: false,
    isListUpdated:false,
    MessageCount: {
      read: 0,
      unread: 0,
      archive: 0
    },
    messageDetails: {},
    messageList: [],
    serviceGroupCollections: [],
    messageFrom: moment().format('YYYY-MM-DD'),
    messageTo: moment().format('YYYY-MM-DD'),
    isArchivepage: false
  },
  getters: {
    messageDetails: state=>{state.messageDetails}
  },
  actions: {
    async getMessageCount({ commit }) {
      messages().then((res) => {
        commit('SET_MessageCount', res);
      });
    },
    async getMessageDetail({ commit }, messageid ) {
      messageDetail({ messageid }).then((res) => {
        commit('SET_MessageDetail', res);
      });
    },
    async getMessageList({ commit,state }) {
      messageList(state.isArchivepage).then((res) => {
        commit('SET_MessageList', res);
      });
    },
    SetEmptyMessageList({commit}) {
      commit('Set_EmptyMessageList');
    },
    async getMessageRead({dispatch },  messageid) {
      readMessage({ messageid }).then(() => {
        dispatch('getMessageCount');
      });
    },
    SetIsArchivepage({commit},val) {
      commit('Set_IsArchivepage',val);
    },
  },
  mutations: {
    SET_MESSAGE_DATE(state, dateObj) {
      state.bookingFrom = moment(dateObj.from).format('YYYY-MM-DD');
      state.bookingTo = moment(dateObj.to).format('YYYY-MM-DD');
    },
    SET_MessageCount(state, payload) {
      state.MessageCount = {
        read: payload.response.MessageCount.Read,
        unread: payload.response.MessageCount.Unread,
        archive: payload.response.MessageCount.Archive
      }

    },
    SET_MessageDetail(state, payload) {
      
      state.messageDetails = payload.response.message;
    },
    SET_MessageList(state, payload) {
      state.messageList = payload.response;
      let messageList = payload.response;
      let serviceGroupCollections = []
      messageList.forEach(message=>{
        serviceGroupCollections.push(message.messages.ServiceTypeGroup)
      })
      serviceGroupCollections = _.uniqBy(serviceGroupCollections, (obj)=>obj.UUID);
      let serviceGroupfilteredArray = serviceGroupCollections.filter(item => item.Name !== "" && item.Icon !== "");
      state.serviceGroupCollections = serviceGroupfilteredArray;
      state.isListUpdated=true;
    },
    Set_EmptyMessageList(state) {
      state.messageList =[]
      state.isListUpdated = false;
    },
    Set_IsArchivepage(state, payload) {
      state.isArchivepage = payload
    },
    
  }
}