import { Interseptor } from './interseptor';
import { constants } from '../constants/constant';
let localStoragePrefix = 'vcl_base_';

const axios = require('axios');
const instanceUrl = axios.create({
  baseURL: constants.API_URL,
  transformRequest: [
    function(data, headers) {
      let accessToken = localStorage.getItem(localStoragePrefix + 'access_token');
      if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken;
      }
      return JSON.stringify(data);
    },
  ],
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
});

/**
 * httpservice for get and post json formatted data
 * @returns {*}
 */
export const httpservice = () => {
  instanceUrl.interceptors.request.use((request) => Interseptor.requestHandler(request));
  instanceUrl.interceptors.response.use(
    (response) => Interseptor.successHandler(response),
    (error) => Interseptor.errorHandler(error)
  );
  return instanceUrl;
};
