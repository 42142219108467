<template>
    <div class="vcs-notification-item" :class="get_state" aria-label="notification">
        <div class="vcs-headline"><span>{{ item.Title }}</span></div>
        <p class="m-0">{{ item.Message }}</p>            
        <p class="text-right p-1 m-0">     
        <a href="#" class="m-0" @click="item_handler(item,'hide')"><b-icon icon="check"></b-icon>{{ get_translation('App','NotificationRead','label') }}</a>    
        </p>
    </div>
</template>

<script>
import { vcm } from '@/mixins/vcm'

export default {
    mixins: [vcm],
    name: 'NotificationItem',
    props: {       
        show: Boolean,         
        item: Object
    },
    data() {
      return {
          state: this.show
      }
    },
    computed: {      
        get_state: function(){
            var rv = ''
            if (this.state == true) {
                rv = 'show'
            }
            return rv
        }
    },
    methods: {      
      item_handler(item,mode) {
          switch (mode) {
              case 'hide': 
                this.state = false 
                break
          }
      }
    }
    
}
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";



.vcs-notification-item { padding: 12px 24px; display: none; min-width: 380px; background-color: #ffffff; border-top: 1px solid #ffffff; border-bottom: 1px solid #d7d7d7; }
.vcs-notification-item.show { display: block; }
.vcs-notification-item .vcs-headline { color: $highlightFontColor; font-size: 1rem; }
.vcs-notification-item p { font-size: 1rem;  }
.vcs-notification-item a { font-size: 1rem; line-height: 1.2rem; padding: 3px; border-width: 1px; text-decoration: none; color: $highlightFontColor; margin-right: 1rem; font-weight: 400; }
.vcs-notification-item a .b-icon { font-size: 1.2rem; line-height: 1.2rem; margin-right: 0.2rem; }

.vcs-notification-item a:focus { outline: none; border-top: none; border-left: none; border-right: none; border-bottom: 1px solid $focusHighlightColor; }

</style>
