<template>
  <ul class="nav flex-column mb-0 overflow-hidden vgs-main-nav" :class="mode">
    <li class="nav-item" v-for="item in get_menu_items()" :key="item.name">
        <router-link
          :to="{ name: item.route }"
          class="nav-link Pmenu"
          :class="get_selected(item)"

        >
          <IconSet :icon="item.icon" :state="get_selected(item)"></IconSet>
          <div class="vgs-link-text-box">
            <div class="vgs-link-text">
              {{ get_translation("App", item.Label, "label") }}
            </div>
            <span
              v-if="MessageCount.unread > 0 && item.name === 'Message'"
              class="messagecount-box"
              >{{ MessageCount.unread }}</span
            >          
          </div>
        </router-link>
      <div  v-if=" $route.name=='archiv' || $route.name=='message'">
        <div v-if="($root.get_menu_state === 'on' && !isTablet) || isMobile" class="LeftSpace">
        <li class="nav-item space" v-for="child in item.children" :key="child.name">
          <router-link
            :to="{ name: child.route }"
            class="nav-link"
            :class="get_selected(child)"
          >
            <IconSet :icon="child.icon" :state="get_selected(child)"></IconSet>
            <div class="vgs-link-text-box">
              <div class="vgs-link-text Smenu">
                {{ get_translation("App", child.name, "label") }}
              </div>
            </div>
          </router-link>
        </li>
        </div>
        <div v-else-if="$root.get_menu_state === 'int' || ($root.get_menu_state === 'on' && isTablet)">
        <li class="nav-item space" v-for="child in item.children" :key="child.name">
          <router-link
            :to="{ name: child.route }"
            class="nav-link Smenu"
            :class="get_selected(child)"
          >
            <IconSet :icon="child.icon" :state="get_selected(child)"></IconSet>
            <div class="vgs-link-text-box">
              <div class="vgs-link-text">
                {{ get_translation("App", child.name, "label") }}
              </div>
            </div>
          </router-link>
        </li>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import IconSet from "@/structure/IconSet.vue";
import { vcm } from "@/mixins/vcm";
import Vue from "vue";
// import axios from "axios";
import { mapActions } from "vuex";

export default {
  mixins: [vcm],
  name: "MenuNavigation",
  components: {
    IconSet,
  },
  props: {
    mode: String,
  },
  data() {
    return {
      showSubMenu: false,
      labels: {},
       isMobile: false,
       isTablet:false,
      // MessageCount: 5,
      userUUID: null,
    };
  },
  async mounted() {
      this.windowResizeHandler();
    this.getUser();
    this.getmessageCounts();
   
  },
  created : function() {
    window.addEventListener("resize", this.windowResizeHandler);
  },
  destroyed() {
  window.removeEventListener("resize", this.windowResizeHandler);
  },
  methods: {
    ...mapActions({
      getUser: "setting/getUserAction",
      getmessageCounts: "message/getMessageCount",
  
    }),
   windowResizeHandler() {
      // your code for handling resize...
      this.isMobile = window.innerWidth < 600 ? true : false;
      this.isTablet = (window.innerWidth > 1000 && window.innerWidth < 1200) ? true : false;
      
    },
    get_menu_items: function () {
      var checkState = this.getActivationState;
      const FiledLabels = JSON.parse(
        localStorage.getItem(
          "form_field_labels_" +
            `${this.$root.config.localization.language}` +
            "_" +
            "Route"
        )
      );
      if (checkState) {
        var mi = this.$root.get_active_main_menu_items;
        for (var i = 0; i < mi.length; i++) {
          for (var j = 0; j < FiledLabels.Label.length; j++) {
            if (mi[i].route == FiledLabels.Label[j].Name)
              mi[i].Label = FiledLabels.Label[j].Label;
          }
        }
        for (var p = 0; p < mi.length; p++) {
          if (mi[p].Label == "") {
            mi[p].Label = this.get_translation("App", mi[p].name, "label");
          }
        }
        var rv = mi;
        return rv;
      } else {
        var mi_splice = JSON.parse(
          JSON.stringify(this.$root.get_active_main_menu_items)
        );
        for (var k = 0; k < mi_splice.length; k++) {
          if (
            (Vue.vclGet("user_db_stateName", "User") != "Complete" &&
              mi_splice[k].name == "Accounting") ||
            (Vue.vclGet("user_db_stateName", "User") != "Complete" &&
              mi_splice[k].name == "LocationData") ||
            (Vue.vclGet("user_db_stateName", "User") != "Complete" &&
              mi_splice[k].name == "Message")
          ) {
            mi_splice.splice(k, 2);
          } else {
            for (var l = 0; l < FiledLabels.Label.length; l++) {
              if (mi_splice[k].route == FiledLabels.Label[l].Name)
                mi_splice[k].Label = FiledLabels.Label[l].Label;
            }
          }
        }
        for (var q = 0; q < mi_splice.length; q++) {
          if (
            (Vue.vclGet("user_db_stateName", "User") != "Complete" &&
              mi_splice[q].name == "Accounting") ||
            (Vue.vclGet("user_db_stateName", "User") != "Complete" &&
              mi_splice[q].name == "LocationData")
          ) {
            mi_splice.splice(q, 1);
          } else if (mi_splice[q].Label == "") {
            mi_splice[q].Label = this.get_translation(
              "App",
              mi_splice[q].name,
              "label"
            );
          }
        }
        var rv_splice = mi_splice;
        return rv_splice;
      }
    },
    get_selected: function (item) {
      var rv = "";
      if (item && item.name.toLowerCase() === this.$route.name.toLowerCase()) {
        rv += " active ";
      }
      return rv;
    },
  },
  computed: {
    getActivationState: function () {
      return this.$store.getters["setting/getActivationState"];
    },
    MessageCount() {
      return { ...this.$store.state.message.MessageCount };
    },
  },
};


</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";
.messagecount-box {
  background-color: #ffde00;
  color: black;
  font-size: 12px;
  font-weight: 700;
  padding: 0px 4px;
  margin-top: 4px;
  float: right;
}
.Smenu{
  font-size: 0.875rem;
  padding-left: 16px;
}
.LeftSpace{
  margin-left:30px;
}
.space{
  padding-bottom: 0;
}
</style>
