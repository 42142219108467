import Vue from 'vue';

export class vlcDataAccess {
  constructor() {
    this.parent = {};
    this.apiCallCollection = {};
    this.initialized = false;
    this.interrupted = false;

    this.callName = '';
    this.callMethod = '';
    this.callPath = '';
    this.callTokenRequired = false;
    this.callRequestBodyRequired = false;
    this.callParametersRequired = false;
    this.callPathParametersRequired = false;
    this.callData = [];
    this.callRequestBody = null;
    this.callParameters = null;
    this.callPathParameters = [];
    this.callInitialized = false;
    this.callElements = [];

    this.access_token = '';
    this.user_login = '';
    this.user_password = '';
    //this.parameterCollection = []
    this.identifier = '';
    this.receiverType = '';
    this.receiverComponent = {};
    this.receiverView = {};
    this.errorCollection = [];
    this.clientAPIKey = '';
  }

  setReceiver(type, receiver) {
    console.log('inside receiver type : ' + type + ' receiver : ' + receiver);
    this.receiverType = type;
    switch (type) {
      case 'component':
        this.receiverComponent = receiver;
        break;
      case 'view':
        this.receiverView = receiver;
        break;
    }
  }

  setIdentifier(identifier) {
    console.log('inside identifier : ' + identifier);
    this.identifier = identifier;
  }

  initialize(parentObject, apiCallCollection) {
    this.parent = parentObject;

    if (apiCallCollection) {
      this.apiCallCollection = apiCallCollection;
      this.initialized = true;
    }
  }

  resetCall() {
    this.callName = '';
    this.callMethod = '';
    this.callPath = '';
    this.callTokenRequired = false;
    this.callRequestBodyRequired = false;
    this.callParametersRequired = false;
    this.callPathParametersRequired = false;
    this.callData = [];
    this.callRequestBody = null;
    this.callParameters = null;
    this.callPathParameters = [];
    this.callInitialized = false;
    this.callElements = [];
  }

  getCall() {
    var rv = {
      callName: this.callName,
      callMethod: this.callMethod,
      callPath: this.callPath,
      callTokenRequired: this.callTokenRequired,
      callRequestBodyRequired: this.callRequestBodyRequired,
      callParametersRequired: this.callParametersRequired,
      callPathParametersRequired: this.callPathParametersRequired,
      callData: this.callData,
      callRequestBody: this.callRequestBody,
      callParameters: this.callParameters,
      callPathParameters: this.callPathParameters,
      callInitialized: this.callInitialized,
      callElements: this.callElements,
    };

    return rv;
  }

  setCall(callObj) {
    this.callName = callObj.callName;
    this.callMethod = callObj.callMethod;
    this.callPath = callObj.callPath;
    this.callTokenRequired = callObj.callTokenRequired;
    this.callRequestBodyRequired = callObj.callRequestBodyRequired;
    this.callParametersRequired = callObj.callParametersRequired;
    this.callPathParametersRequired = callObj.callPathParametersRequired;
    this.callData = callObj.callData;
    this.callRequestBody = callObj.callRequestBody;
    this.callParameters = callObj.callParameters;
    this.callPathParameters = callObj.callPathParameters;
    this.callInitialized = callObj.callInitialized;
    this.callElements = callObj.callElements;
  }

  /*
    addParameter(parName,parValue) {
        this.parameterCollection.push({name: parName, value: parValue})
    }*/

  setDataAccessExceptionRegister(exceptionObject) {
    if (exceptionObject) {
      this.parent.data_access_exception_register_index++;

      this.parent.data_access_exception_register.push({
        name: this.callName,
        identifier: this.identifier,
      });

      this.parent.data_access_exception_register_collection.push({
        index: this.parent.data_access_exception_register_index,
        name: this.callName,
        data: exceptionObject,
        receiverType: this.receiverType,
        receiverComponent: this.receiverComponent,
        receiverView: this.receiverView,
        identifier: this.identifier,
      });
    }
  }

  setDataAccessRegister(dataObject) {
    if (dataObject) {
      var registerObject = {
        index: this.parent.data_access_register_index,
        name: this.callName,
        data: dataObject,
        receiverType: this.receiverType,
        receiverComponent: this.receiverComponent,
        receiverView: this.receiverView,
        identifier: this.identifier,
      };

      this.parent.data_access_register_index++;

      this.parent.data_access_register.push({
        name: this.callName,
        identifier: this.identifier,
      });

      this.parent.data_access_register_collection.push(registerObject);
    }
  }

  resetDataAccessRegister() {
    /*
        var pCall = call
        var dataCollectionObject = this.parent.data_access_data_collection.filter(function (obj) {
            return obj.name == pCall
        })
        
        
        */
    /*
        this.parent.data_access_register_index++;        

        this.parent.data_access_register.push({            
            name: dataCollectionObject.callName, 
            identifier: dataCollectionObject.identifier
        })        

        this.parent.data_access_register_collection.push(dataCollectionObject.data)
        */
  }

  assembleCallPath(path) {
    var rv = '';

    rv += this.parent.config.api.protocol + '://';
    rv += process.env.VUE_APP_API_BASE_URL;

    //rv += '/' + this.parent.language
    if (path != '' && path != undefined) {
      rv += path;
    }

    if (this.callPathParameters.length > 0) {
      for (var i = 0; i < this.callPathParameters.length; i++) {
        var parSet = this.callPathParameters[i];
        rv = rv.replace('{' + parSet['name'] + '}', parSet['value']);
      }
    }

    //var ts = new Date().getTime()
    //rv += '?ts=' + ts

    return rv;
  }

  initCall(name) {
    this.callName = name;
    if (this.callName !== '') {
      var callSet = this.apiCallCollection.filter((call) => call.name === this.callName);
      console.log(callSet);
      if (callSet) {
        this.callMethod = callSet[0].method;
        this.callPath = this.assembleCallPath(callSet[0].path);
        this.callTokenRequired = callSet[0].token_required;
        this.callRequestBodyRequired = callSet[0].request_body_required;
        this.callParametersRequired = callSet[0].parameters_required;
        this.callPathParametersRequired = callSet[0].path_parameters_required;
        this.callElements = callSet[0].elements;
        if (this.callMethod !== '' && this.callPath !== '') {
          this.callInitialized = true;
        }
      }
    }
  }

  validateAccess() {
    if (this.callTokenRequired) {
      this.access_token = Vue.vclGet('access_token', '');
      if (this.access_token === '') {
        // Queue Call //
        this.parent.data_access_register_queue.push(this.getCall());
        // Refresh Token //
      }
    }
  }

  axiosCall() {
    var pThis = this;
    // this.access_token = this.parent.get_access_token;
    if (this.parent.config.api.request_allow_origin == true) {
      Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = document.location.origin;
      Vue.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
      Vue.axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';
    }

    if (this.callTokenRequired && this.access_token !== '') {
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.access_token;
    }

    //'X-API-KEY'//
    this.parent.data_access_call_count++;

    var runCall = true;
    if (this.callRequestBodyRequired) {
      if (!this.callRequestBody) {
        runCall = false;
        Vue.vclDeploy(
          '>> axiosCall >> callRequestBodyRequired / ' + this.parent.data_access_call_count,
          'request body is required!'
        );
      }
    }
    if (this.callParametersRequired) {
      if (!this.callParameters) {
        runCall = false;
        Vue.vclDeploy(
          '>> axiosCall >> callParametersRequired / ' + this.parent.data_access_call_count,
          'parameters are required!'
        );
      }
    }

    if (this.callPathParametersRequired) {
      if (this.callPathParameters.length == 0) {
        runCall = false;
        Vue.vclDeploy(
          '>> axiosCall >> callPathParametersRequired / ' + this.parent.data_access_call_count,
          'path parameters are required!'
        );
      }
    }

    if (runCall) {
      Vue.vclDeploy('>> axiosCall >> callPath / ' + this.parent.data_access_call_count, this.callPath);
      if (this.callParameters) {
        Vue.vclDeploy('>> axiosCall >> callParameters / ' + this.parent.data_access_call_count, this.callParameters);
      }

      Vue.axios({
        method: pThis.callMethod,
        url: pThis.callPath,
        responseType: 'json',
        headers: {
          'Content-type': 'application/json',
        },
        data: pThis.callRequestBody,
        params: pThis.callParameters,
      })
        .then(function(response) {
          pThis.finalize(response);
        })
        .catch(function(error) {
          pThis.handleException(error);
        });
    }
  }

  /*
    assembleParameters () {
        if (this.parameterCollection.length > 0) {            
            for (var i = 0; i < this.parameterCollection.length; i++) {
                var parSet = this.parameterCollection[i]
                this.callData[parSet['name']] = parSet['value']
            }
        }
    }*/

  setRequestBody(obj) {
    this.callRequestBody = obj;
  }

  setParameters(obj) {
    this.callParameters = obj;
  }

  addPathParameter(parName, parValue) {
    console.log(parName);
    this.callPathParameters.push({ name: parName, value: parValue });
  }

  call(name) {
    if (this.callInitialized == false) {
      this.initCall(name);
    }

    if (this.callInitialized === true) {
      this.validateAccess();
      if (this.interrupted === false) {
        switch (this.parent.config.api.data_provider) {
          default:
            this.axiosCall();
            break;
        }
      }
    }
  }

  bounce(mode) {
    this.parent.data_access_bounce_register_index++;

    this.parent.data_access_bounce_register.push({
      identifier: this.identifier,
      mode: mode,
      receiverType: this.receiverType,
      receiverComponent: this.receiverComponent,
      receiverView: this.receiverView,
      data: null,
    });

    this.resetCall();
  }

  finalize(responseObj) {
    var dataObject = responseObj.data;
    this.setDataAccessRegister(dataObject);
    if (dataObject) {
      Vue.vclDeployInfo(
        '>> ACCESS >> finalize.' + this.callName + '|' + responseObj.status + '|' + responseObj.statusText,
        dataObject
      );
    }

    this.resetCall();
  }

  handleException(exceptionObject) {
    this.setDataAccessExceptionRegister(exceptionObject);
    if (exceptionObject) {
      Vue.vclDeployInfo('>> ACCESS >> handleException.' + this.callName, exceptionObject);
    }

    this.resetCall();
  }
}
