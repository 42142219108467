<script>
import { Doughnut, mixins} from 'vue-chartjs';
import Chart from 'chart.js';
Chart.platform.disableCSSInjection = true;



export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }, 
  },
  mixins: [mixins.reactiveProp],
  data () {
    return {
      isChartMount : false
    }
  },
    mounted () {
    this.renderChart(this.chartData, this.options);
  },
  methods:{
    update() {
      this.$data._chart.update();
    }
  }

}
</script>

<style>
@keyframes chartjs-render-animation {
	from {
		opacity: .99
	}
	to {
		opacity: 1
	}
}

.chartjs-render-monitor {
	animation: chartjs-render-animation 1ms
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
	position: absolute;
	direction: ltr;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	visibility: hidden;
	z-index: -1
}

.chartjs-size-monitor-expand>div {
	position: absolute;
	width: 1000000px;
	height: 1000000px;
	left: 0;
	top: 0
}

.chartjs-size-monitor-shrink>div {
	position: absolute;
	width: 200%;
	height: 200%;
	left: 0;
	top: 0
}
</style>
