import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vcl from './vcl';
import store from '@/state/store';
import moment from 'moment';

Vue.use(vcl);

// eslint-disable-next-line
import { vlcDataAccess } from '@/library';
import { Plugin } from 'vue-fragment';
Vue.use(Plugin);

Vue.use(VueAxios, axios);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';
import router from './router';

import systemConfig from '../config.json';

import apiScopeTranslationDE from '../static/api_scope_translation_de.json';
import apiCallCollection from '../static/api_call_collection.json';

Vue.config.productionTip = false;

new Vue({
  router,
  systemConfig,
  apiScopeTranslationDE,
  apiCallCollection,
  data() {
    return {
      system_state: 'started',
      access_state: '',
      access_token: '',
      data_access: {},
      data_access_register_index: 0,
      data_access_register: [],
      data_access_register_collection: [],
      data_access_exception_register_index: 0,
      data_access_exception_register: [],
      data_access_exception_register_collection: [],
      data_access_bounce_register_index: 0,
      data_access_bounce_register: [],
      data_access_call_count: 0,
      data_access_register_queue: [],

      menu_state: 'on',
      menu_state_recovery: 'on',

      notifications: {},

      notifications_menu_item_count: 4,

      alerts: [],

      responsive_marker: '',
      responsive_marker_collection: [],
      window_width: window.innerWidth,

      setup_collection: [],
      meta_links: {},

      source: {},
      source_address_list: {},
      source_partner_list: {},
      source_selection_list: [],
      source_selection_options: [],
      config: {},

      user_notifications_read: [],
      service_type_groups: [],
      user_set: {},
      user_address_list: [],
      user_addresses: [],
    };
  },
  beforeCreate: function() {},
  created: function() {
    // Configuration //
    this.config = systemConfig;
    Vue.vclSet('system_debug_console', this.config.system.debug.console, 0);

    // HTML Page //
    this.set_document_title('route', '', '');

    // Conditions //
    var conditionsPassed = true;
    if (this.config.conditions.require_ssl == true) {
      var locationProtocol = document.location.protocol.replace(':', '');
      if (locationProtocol != 'https') {
        conditionsPassed = false;
      }
    }
    Vue.vclSet('system_conditions_passed', conditionsPassed);
    // Initial Data Access //
    this.data_access = new vlcDataAccess();
    this.data_access.initialize(this, apiCallCollection);

    this.data_access1 = new vlcDataAccess();
    this.data_access1.initialize(this, apiCallCollection);
    this.$root.data_access1.setIdentifier('form.labels');
    this.$root.data_access1.addPathParameter('language', this.$root.config.localization.language);
    this.$root.data_access1.call('form_labels');
  },
  computed: {
    Register() {
      return this.$store.state.setting.RegisterKeySet;  
    },
    get_notification_menu_items: function() {
      // console.log("this.notifications", moment(new Date()).format("DD/MM/YYYY"), this.notifications, Vue.vclGet('user_db_stateName', ''));
      if (this.notifications.length > 0) {
        var itemLimit = this.notifications_menu_item_count * -1;
        return this.notifications.slice(itemLimit);
      } else {
        return this.notifications;
      }
    },
    get_notifications: function() {
      return this.notifications;
    },
    get_menu_state: function() {
      return this.menu_state;
    },
    get_access_token: function() {
      return Vue.vclGet('access_token', '') || this.access_token;
    },

    get_service_group_type: function() {
      return Vue.vclGet('service_type_groups', '') && JSON.parse(Vue.vclGet('service_type_groups', ''));
    },
    get_user_state_id: function() {
      return Vue.vclGet('user_state_id', '') && JSON.parse(Vue.vclGet('user_state_id', ''));
    },

    get_user_state: function() {
      return Vue.vclGet('user_state', '');
    },

    get_user_uuid: function() {
      return Vue.vclGet('user_uuid', '');
    },

    get_user_address_list: function() {
      if (!JSON.parse(this.user_addresses.length)) {
        return Vue.vclGet('user_address_list', '') && JSON.parse(Vue.vclGet('user_address_list', ''));
      } else {
        return JSON.parse(this.user_addresses);
      }
    },

    get_active_main_menu_items: function() {
      return this.config.menu.main_menu_items.filter(function(obj) {
        return obj.state == 'on';
      });
    },
    get_active_user_menu_items: function() {
      const bothValuesFilled =this.$store.state.setting.NotFullyRegisteredUser;
      return this.config.menu.user_menu_items.filter(function(obj) {
        const state = typeof obj.state === 'function' ? obj.state() : obj.state;
        const action = obj.action;
        if (bothValuesFilled && action === 'route' && obj.route === 'passwortandern') {
          return false;
        }
        return (bothValuesFilled && (action === 'route' || action === 'sign.out')) || (state === 'on');
      });
    },
    get_menu_mode_button_layout: function() {
      return this.config.menu.mode_button_layout;
    },
    get_content_copyright: function() {
      return this.content_copyright;
    },
    get_user_salutation: function() {
      var rv = '';
      rv += this.get_translation('App', 'GoodDay', 'label');

      var name = this.get_user_info('Name', '');
      var firstName = this.get_user_info('FirstName', '');
      if (firstName != '') {
        name = firstName; 
      }

      rv = Vue.vclAppend(rv, name, ', ');
/*
      if (name != '') {
        rv += ', ';
        rv += name;
      }
*/

      return rv;
    },
  },
  methods: {
    get_user_info(column, dv) {
      var rv = '';

      if (this.user_set) {
        if (this.user_set.User) {
          if (column != '') {
            if (this.user_set.User[column]) {
              rv = this.user_set.User[column];
            }
          }
        }
      }

      if (rv.trim() == '') {
        rv = dv;
      }

      return rv;
    },
    get_source_info(column, dv) {
      var rv = '';

      if (this.source) {
        if (column != '') {
          if (this.source[column]) {
            rv = this.source[column];
          }
        }
      }

      if (rv.trim() == '') {
        rv = dv;
      }

      return rv;
    },
    set_system_user(mode, userObj, credentials) {
      var rv = false;
      var vc = 0;

      let accessToken = '';
      var refreshToken = '';
      var userName = '';

      if (userObj) {
        if (userObj.AccessToken != '') {
          accessToken = userObj.AccessToken;
          this.access_token = userObj.AccessToken;
          vc++;
        }
        if (userObj.RefreshToken != '') {
          refreshToken = userObj.RefreshToken;
        }

        if (userObj.ReadNotifications) {
          this.user_notifications_read = userObj.ReadNotifications;
        }

        if (userObj.UserSet && userObj.UserSet.Addresses) {
          let userAddress = [...userObj.UserSet.Addresses];
          this.user_addresses = JSON.stringify(userAddress);
        }

        if (userObj.ServiceTypeGroups) {
          this.service_type_groups = JSON.stringify(userObj.ServiceTypeGroups);
        }

        if (userObj.UserSet) {
          this.user_set = userObj.UserSet;
          vc++;
          if (this.user_set.User) {
            userName = this.user_set.User.Name;
          }
          if (this.user_set.Addresses) {
            this.user_address_list = [];
            for (var i = 0; i < this.user_set.Addresses.length; i++) {
              var adObj = this.user_set.Addresses[i];
              var emblem = '';
              if (adObj.Source) {
                emblem = adObj.Source.Emblem;
              }

              var entry = {
                AddressId: adObj.AddressId,
                Type: adObj.UserAddressTypeName,
                Street: adObj.Street,
                StreetNumber: adObj.StreetNumber,
                ApartmentNumber: adObj.ApartmentNumber,
                Staircase: adObj.Staircase,
                PostalCode: adObj.PostalCode,
                City: adObj.City,
                ValidFrom: adObj.ValidFrom,
                ValidTo: adObj.ValidTo,
                Emblem: emblem,
              };
              this.user_address_list.push(entry);
            }
          }
        }

        if (vc == 2) {
          rv = true;
          switch (mode) {
            case 'sign.in':
              Vue.vclSet('user_name', userName, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('user_state', 'signed.in', this.config.api.token_expiration_in_minutes);
              Vue.vclSet('user_db_type_name', credentials.TypeName, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('user_db_state_name', credentials.StateName, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('user_db_stateName', userObj.UserSet.User.StateName, this.config.api.token_expiration_in_minutes);

              Vue.vclSet('user_login', credentials.EMail, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('service_type_groups', this.service_type_groups, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('user_state_id', 1);
              Vue.vclSet('user_address_list', this.user_addresses, this.config.api.token_expiration_in_minutes);

              Vue.vclSet('user_uuid', this.user_set.User.Uuid, this.config.api.token_expiration_in_minutes);

              Vue.vclSet('access_token', accessToken, this.config.api.token_expiration_in_minutes);
              Vue.vclSet('refresh_token', refreshToken, this.config.api.token_expiration_in_minutes);
              break;

            case 'reload':
              Vue.vclUpdate('access_token', accessToken);
              Vue.vclUpdate('refresh_token', refreshToken);
              break;
          }

          this.update_notifications();
        }
      }
      return rv;
    },

    sign_out() {
      this.access_token = '';
      Vue.vclSet('user_name', '', 0);
      Vue.vclSet('user_state', '', 0);

      Vue.vclSet('user_db_type_name', '', 0);
      Vue.vclSet('user_db_state_name', '', 0);

      Vue.vclSet('user_login', '', 0);

      Vue.vclSet('access_token', '', 0);
      Vue.vclSet('refresh_token', '', 0);
      Vue.vclSet('user_uuid', '', 0);
      Vue.vclSet('service_type_groups', '', 0);
      Vue.vclSet('user_address_list', '', 0);
      localStorage.removeItem('vcl_base_user_address_list');
      localStorage.removeItem('vcl_base_service_type_groups');
      localStorage.removeItem('getalternate');
      localStorage.removeItem('alternate');

      // this.$router.go({ name: 'signin' });
      // this.goto('signin');
    },

    update_notifications() {
      this.data_access.setIdentifier('notifications.update');
      var parameters = {
        sourceUUID: this.get_source_info('Uuid', ''),
        userUUID: Vue.vclGet('user_uuid',''),
      };
      this.data_access.setParameters(parameters);
      this.data_access.call('notifications');

      // Notifications Interval //
      /*
      var notification_update_interval = setInterval(function () {


        clearTimeout(notification_update_interval) // temporary //

      }.bind(this), 5000); // GET INTERVAL FROM SETUP!!! 
      */
    },
    validate_reload_system_user() {
      //var accessToken = Vue.vclGet('access_token','')
      /*
      var userState = Vue.vclGet('user_state','')
      if (userState == 'signed.in') {
        var vc = 0
        
        var accessToken = Vue.vclGet('access_token','')
        if (accessToken != '') {
          vc++  
        }
        
        if (this.user_set) {
          if (this.user_set.User) {
            vc++
          }
        }      
        
        if (vc < 2) { // Reload Required // 
          var userTypeName = Vue.vclGet('user_db_type_name','')
          var userStateName = Vue.vclGet('user_db_state_name','')       
          var userLogin = Vue.vclGet('user_login','')
          var userPassword = Vue.vclGet('user_password','')
          
          if (userLogin != '' && userPassword != '' && userTypeName != '' && userStateName != '') {
            
            //this.data_access.setIdentifier('system.user.reload');
            //this.data_access.setRequestBody(this.get_user_credentials(userTypeName,userStateName,userLogin,userPassword))            
            //this.data_access.call('signin');
          
          } else {
            //this.sign_out()
          }
        }
        
      } */
    },
    get_user_credentials(typeName, stateName, email, password) {
      var rv = {
        TypeName: typeName,
        StateName: stateName,
        EMail: email,
        Password: password,
      };
      return rv;
    },
    get_sign_in(language) {
      var rv = {
        language: language,
      };
      return rv;
    },
    set_document_title(mode, nameSpace, name) {
      var rv = '';
      switch (mode.toLowerCase()) {
        case 'label':
            var map = {
              passwortandern : "Passwort ändern",
              forgotpassword : "Passwort vergessen",
              Activation : "Aktivierung",
              confirmpassword : "Passwort bestätigen"
          }
          rv = map[name] ? map[name] : this.get_translation(nameSpace, name, 'label');
          break;
        case 'route':
          rv = this.get_translation('Route', this.$route.name, 'label');
          break;
        case 'custom':
          rv = name;
          break;
      }
      rv += ' | ' + Vue.vclGet('application_title', '');

      document.title = rv;
    },
    set_system_settings(setupObj) {
      if (setupObj) {
        if (setupObj.length > 0) {
          for (var i = 0; i < setupObj.length; i++) {
            var setupSet = setupObj[i];
            this.setup_collection.push({ name: setupSet.TypeName, value: setupSet.Value });
          }
        }
      }
    },
    get_system_setting(typeName, dv) {
      var rv = dv;
      var pTypeName = typeName;

      var settings = this.setup_collection.filter(function(obj) {
        return obj.name == pTypeName;
      });

      if (settings) {
        if (settings.length > 0) {
          if (settings[0].value.trim() != '') {
            rv = settings[0].value.trim();
          }
        }
      }

      return rv;
    },
    set_system_state(stateValue) {
      Vue.vclSet('system_state', stateValue, 0);
      this.system_state = stateValue;
    },
    get_system_state() {
      return Vue.vclGet('system_state', '');
    },
    goto(routeName) {
      if (routeName != this.$route.name) {
        Vue.vclDeploy(':: ROUTE >> main.goto', routeName);
        this.$router.push({ name: routeName }).catch();
      }
    },
    get_translation(nameSpace, name, column) {
      return this.get_translation_by_type('Default', nameSpace, name, column);
    },
    get_type_translation(nameSpace, name, column) {
      return this.get_translation_by_type('Type', nameSpace, name, column);
    },
    get_translation_by_type(typeName, nameSpace, name, column) {
      var rv = '';

      var translationSet = null;
      switch (this.config.localization.language) {
        default:
          // DE only / for now ... //
          translationSet = apiScopeTranslationDE.filter(function(obj) {
            return obj.TypeName == typeName && obj.NameSpace == nameSpace && obj.Name == name;
          });
          break;
      }

      if (translationSet.length > 0) {
        if (translationSet[0]) {
          switch (column.toLowerCase()) {
            case 'tooltip':
              rv = translationSet[0].ToolTip;
              if (rv == '') {
                rv = translationSet[0].Label;
              }
              break;

            case 'ariadescription':
              rv = translationSet[0].AriaDescription;
              if (rv == '') {
                rv = translationSet[0].Label;
              }
              break;

            case 'help':
              rv = translationSet[0].Help;
              break;

            case 'template':
              rv = translationSet[0].Template;
              break;

            default:
              rv = translationSet[0].Label;
              break;
          }
        }
      }

      if (rv == '') {
        rv = Vue.vclCapitalize(name);
        rv = rv.replace(/_/g, ' ');
      }
      return rv;
    },
    add_alert(parName, parDescription, parIcon) {
      var ic = this.alerts.length + 2;
      this.alerts.push({ index: ic, name: parName, description: parDescription, icon: parIcon });
    },
    add_to_path(parName, parValue) {
      this.path_collection.push({ name: parName, value: parValue });
    },
    select_path(pathObj) {
      this.path_selected = pathObj;
    },
    reset_path() {
      this.path_collection = [];
    },
    get_filter_types: function(parView) {
      return this.config.filter.types.filter(function(obj) {
        return obj.view == parView;
      });
    },

    get_label_fields(language, page) {
      if (
        localStorage.getItem('form_field_labels_' + language + '_' + page) === null ||
        localStorage.getItem('form_field_labels_' + language + '_' + page) === undefined
      ) {
        const Url = process.env.VUE_APP_BASE_URL + `/v1/labels/${this.$root.config.localization.language}`;
        axios.get(Url).then(function(response) {
          if (response.data.responseCode === '200' && response.data.response) {
            for (var j = 0; j < response.data.response.length; j++) {
              localStorage.setItem(
                'form_field_labels_' + language + '_' + response.data.response[j].Namespace,
                JSON.stringify(response.data.response[j])
              );
            }
          }
          const FiledLabels = JSON.parse(localStorage.getItem('form_field_labels_' + language + '_' + page));
          var labels = {};
          for (var i = 0; i < FiledLabels.Label.length; i++) {
            labels[FiledLabels.Label[i].Name] = FiledLabels.Label[i].Label;
          }
          return labels;
        });
      } else {
        const FiledLabels = JSON.parse(localStorage.getItem('form_field_labels_' + language + '_' + page));
        var labels = {};
        for (var i = 0; i < FiledLabels.Label.length; i++) {
          labels[FiledLabels.Label[i].Name] = FiledLabels.Label[i].Label;
        }
        return labels;
      }
    },
  },
  watch: {
    /*system_state: function (value) {
      switch (value) {
        default:
          Vue.vclDeploy('watch.system_state',value)     
          break
      }
    },*/
    data_access_exception_register: function() {
      if (this.data_access_exception_register_collection.length > 0) {
        for (var i = 0; i < this.data_access_exception_register_collection.length; i++) {
          var exObj = this.data_access_exception_register_collection[i];
          switch (exObj.identifier) {
            case 'system.initial.token':
            case 'system.init':
              this.set_system_state('call.interupted');
              break;

            default:
              break;
          }
        }
      }
    },
    data_access_bounce_register: function() {
      if (this.data_access_bounce_register.length > 0) {
        for (var i = 0; i < this.data_access_bounce_register.length; i++) {
          var daObj = this.data_access_bounce_register[i];
          if (daObj) {
            switch (daObj.mode) {
              case 'user_address_list':
                daObj.data = this.user_address_list;
                break;
            }

            switch (daObj.receiverType) {
              case 'view':
                if (daObj.receiverView) {
                  daObj.receiverView.receive(daObj);
                }
                break;

              case 'component':
                if (daObj.receiverComponent) {
                  daObj.receiverComponent.receive(daObj);
                }
                break;
            }
          }
        }
      }
    },
    data_access_register: function() {
      if (this.data_access_register_collection.length > 0) {
        for (var i = 0; i < this.data_access_register_collection.length; i++) {
          var daObj = this.data_access_register_collection[i];
          switch (daObj.identifier) {
            case 'system.initial.token':
              if (daObj.data) {
                if (daObj.data.response) {
                  this.access_token = '';
                  Vue.vclSet(
                    'access_token',
                    daObj.data.response.AccessToken,
                    this.config.api.token_expiration_in_minutes
                  );
                }
              }
              break;

            case 'system.init':
              // Setup //
              this.set_system_settings(daObj.data.response.Setup);

              // Validation //
              var nextSystemState = 'system.initialized';

              if (!this.setup_collection || !this.source_partner_list || !this.source_selection_list) {
                nextSystemState = 'system.initialization.failed';
              }

              this.set_system_state(nextSystemState);

              //this.validate_reload_system_user()

              if (
                Vue.vclGet('user_state', '') != 'signed.in' &&
                this.$route.name !== 'Activation' &&
                this.$route.name !== 'Validateuser' &&
                this.$route.name !== 'AGB' &&
                this.$route.name !== 'Feedback' &&
                this.$route.name !== 'Impressum' &&
                this.$route.name != 'signup' &&
                this.$route.name != 'signinEID'
              ) {
                this.goto('signin');
              }
              break;

            case 'system.user.reload':
              this.set_system_user('reload', daObj.data.response, null);

              break;

            case 'notifications.update':
              this.notifications  = daObj?.data?.response?.filter((notification) => {
                  if(moment(notification.Expired) >= moment()) {
                    return notification;
                  }
              });
              // this.notifications = daObj.data.response;
              break;

            case 'form.labels':
              if(Vue.vclGet('user_uuid','')) this.update_notifications();
              if (daObj.data.responseCode === '200' && daObj.data.response) {
                for (var j = 0; j < daObj.data.response.length; j++) {
                  localStorage.setItem(
                    'form_field_labels_' +
                      this.$root.config.localization.language +
                      '_' +
                      daObj.data.response[j].Namespace,
                    JSON.stringify(daObj.data.response[j])
                  );
                }
              }
              break;

            default:
              switch (daObj.receiverType) {
                case 'view': {
                  if (daObj.receiverView) {
                    daObj.receiverView.receive(daObj);
                  }
                  break;
                }

                case 'component': {
                  if (daObj && typeof daObj.receiverComponent.receive === 'function') {
                    daObj.receiverComponent.receive(daObj);
                  }
                  break;
                }
              }
              break;
          }

          this.data_access_register_collection.splice(i);
        }
      }
    },
  },
  store,
  directives: {},
  render: (h) => h(App),
}).$mount('#app');
