import { httpservice } from './httpService';
import { constants } from '../constants/constant';

export function initialize() {
  return httpservice()
    .get(constants.API_URL + constants.API.INITIALIZE)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
