<template>
  <div class="vgs-jacket" role="banner">
    <div class="container-fluid p-0 m-0 h-100 w-100">
    <div class="row h-100 p-0 m-0">
      <div class="col-12 vcs-welcome">
        <div class="vcw-top-right"><Triangles mode="top-right" /></div>
        <div class="vcw-bottom-left"><Triangles mode="bottom-left" /></div>
        <div class="container h-100 p-0 m-0">
          <div class="row p-0 m-0 h-100 justify-content-center align-items-center">
            <div class="col-2"></div>
            <div class="col-8 vcs-jacket-title">

              <div class="text-center">
               <router-link to="/"><img v-bind:src="get_asset_image('logo_dk.png')" :alt="labels.PlattformLogo" width="" /></router-link>
              </div>
              <br/>
              <router-link to="/" class="text-decoration-none"><h1 class="text-center">{{ applicationTitle }}</h1></router-link>

            </div>            
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   
</template>

<script>

import Triangles from '@/components/Triangles.vue'
import { vcm } from '@/mixins/vcm'

export default {
  mixins: [vcm],
  name: 'Jacket',
  components: {
    Triangles
  },
  props: {    
      mode: String
  },
  data() {
    return {      
      labels: {
        PlattformLogo: this.get_translation('App','PlattformLogo','label'),
        ApplicationTitle : ''
      }
    };
  },
  computed: { 
    applicationTitle() {
      return this.$store.state.initialize.applicationTitle;
    } 
  },
  mounted: function () {
   const FiledLabelApp = this.$root.get_label_fields(`${this.$root.config.localization.language}`,"App");
   Object.assign(this.labels, FiledLabelApp);
  },
  methods: {
   
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-welcome { position: relative; background-color: $highlightColor; overflow: hidden; }
.vcs-welcome .vcw-top-right { position: absolute; top: 0px; right: 0px; width: 90%; text-align: right; }
.vcs-welcome .vcw-bottom-left { position: absolute; bottom: 1px; left: 16px; width: 16px; }

.vcs-jacket-title h1 { font-size: 1.7rem; font-weight: 700; }

</style>
