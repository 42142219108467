<template>
  <div>
    <b-modal
      ref="user-validate-modal"
      id="modal"
      modal-class="user-validate-modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="text-center" v-if="isloading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <p class="my-4 text-center">{{ labels.VerifyYourAccountPleaseWait }}</p>
    </b-modal>
  </div>
</template>

<script>
import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import axios from "axios";
export default {
  name: "userValidation",
  mixins: [vcm, vvm],
  data() {
    return {
      isloading: false,
      forsignin: null,
      forresetpassword: null,
      useruuid: null,
      newParameter: null,

      labels: {
        VerifyYourAccountPleaseWait: this.get_scope_translation(
          "VerifyYourAccountPleaseWait",
          "label"
        ),
      },
      data: {},
    };
  },

  async created() {},

  async mounted() {
    this.showModal();
    this.isloading = true;
    const queryParams = new URLSearchParams(window.location.search);
    let decodeUrl = decodeURIComponent(window.location.href);
    this.useruuid = queryParams.get("useruuid");
    this.forsignin = queryParams.get("forsignin");
    this.TypeValue = queryParams.get("TypeValue");
    this.forresetpassword = queryParams.get("forresetpassword");
    let code =
      decodeUrl &&
      decodeUrl.split("&") &&
      decodeUrl.split("&")[2] &&
      decodeUrl.split("&")[2].split(/=(.+)/)[1];
    if (this.useruuid && code && this.forsignin && this.forresetpassword) {
      var Type;
      if (this.TypeValue === "1") {
        Type = "DoubleOptInEMail";
      } else if (this.TypeValue === "2") {
        Type = "AlternateEmail";
      } else if (this.TypeValue === "4") {
        Type = "DeleteNotificationEmail";
      } else {
        Type = "NewEmail";
      }

      const userUuid = this.$root.get_user_info("useruuid", this.useruuid);
      const data = {
        TypeName: Type,
        Code: code,
      };

      try {
        const Url =
          process.env.VUE_APP_BASE_URL + `/v1/users/${userUuid}/doubleoptin`;
        const response = await axios.post(Url, data);

        if (
          response.data.responseCode === "200" &&
          response.data.responseMessage === "Ok"
        ) {
          if (this.TypeValue === "2") {
            this.$root.sign_out();
            window.location.href = `anmeldung?status=active`;
          } else if (this.TypeValue === "3") {
            this.$root.sign_out();
            window.location.href = `anmeldung?status=success`;
          } else if (this.TypeValue === "4") {
              this.$root.sign_out();
              window.location.href = `anmeldung?status=valid&uuid=${this.useruuid}`;
          } else if (this.forsignin) {
            window.location.href = `anmeldung?status=success`;
          } else {
            window.location.href = `anmeldung?status=failed`;
          }
        } else if (
          response.data.responseCode === "401" &&
          response.data.responseMessage === "Unauthorized" &&
          this.TypeValue === "4"
        ) {
          this.$root.sign_out();
          window.location.href = `anmeldung?status=expired`;
        } else if (
          response.data.responseCode === "401" &&
          response.data.responseMessage === "Unauthorized"
        ) {
          this.$root.sign_out();
          window.location.href = `anmeldung?status=invalid`;
        } else {
          this.$root.sign_out();
          window.location.href = `anmeldung?status=failed`;
        }
      } catch (e) {
        console.log("Error : " + e);
      }
    } else {
      this.$router.push({ name: "notfound" });
      return;
    }
  },
  methods: {
    showModal() {
      this.$refs["user-validate-modal"].show();
    },
  },
};
</script>

<style lang="css">
.user-validate-modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
}
.user-validate-modal > div {
  width: 80% !important;
}

div#modal .modal-dialog.modal-md .modal-content > header {
  border-bottom: 0 !important;
}
.spinner-border {
  color: #e3000f !important;
}
</style>