<template>
  <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged" >
    <span><b-icon :icon="icon"></b-icon></span><strong>&nbsp;{{ title }}</strong>
    <p>{{ description }}</p>
    <b-progress variant="success" :max="dismissSecs" :value="dismissCountDown" height="4px" ></b-progress>
  </b-alert>
</template>

<script>
import { vcm } from '@/mixins/vcm'

export default {
    mixins: [vcm],
    name: 'AlertItem',
    props: {
        title: {
          type: String,
          required : true
        },
        description: {
          type: String,
          required : true
        },
        icon: {
          type: String,
          required : true
        }
    },
    data() {
      return {
        dismissSecs: 5,
        dismissCountDown: 5,
        showDismissibleAlert: false
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
    
}
</script>

<style scoped >

</style>
