// eslint-disable-next-line
import Vue from 'vue';

export const customLabels = {
  data() {
    return {
      appLabels: {
        NoActiveNotifications: this.get_translation('App', 'NoActiveNotifications', 'label'),
        Settings: this.get_translation('App', 'Settings', 'label'),
        SignOut: this.get_translation('App', 'SignOut', 'label'),
        ResetPassword: this.get_translation('App', 'ResetPassword', 'label'),
        Notifications: this.get_translation('App', 'Notifications', 'label') + ' *',
        PlattformLogo: this.get_translation('App', 'PlattformLogo', 'label'),
        PlattformLogoSmall: this.get_translation('App', 'PlattformLogoSmall', 'label'),
      },
      signIn: {
        NoEGovernmentAccountYet: this.get_scope_translation('NoEGovernmentAccountYet', 'label'),
        RegisterNow: this.get_scope_translation('RegisterNow', 'label'),
        EIDGoToSignIN: this.get_scope_translation('EIDGoToSignIN', 'label'),
        EIDGoToSignINMandate: this.get_scope_translation('EIDGoToSignINMandate', 'label'),
        ForgotPassword: this.get_scope_translation('ForgotPassword', 'label'),
        EmailVerificationSuccess: 'KAERstin – E-Mail-Adresse aktivieren',
        EmailVerificationFailed: 'KERstin - E-Mail-Adresse Aktivierung fehlgeschlagen',
        GoToSignIn: this.get_translation('App', 'GoToSignIn', 'label'),
        AlternateEmailVerificationSuccess:  this.get_scope_translation('AlternateEmailVerificationSuccess', 'label'),
        AlternateEmailVerificationFailed:  this.get_scope_translation('AlternateEmailVerificationFailed', 'label'),
      },
      signUp: {
        SignUp: this.get_scope_translation('SignUp', 'label'),
        Introduction: this.get_scope_translation('Introduction', 'label'),
        RegisterAs: this.get_scope_translation('RegisterAs', 'label'),
        NaturalPerson: this.get_scope_translation('NaturalPerson', 'label'),
        LegalPerson: this.get_scope_translation('LegalPerson', 'label'),
      },
      LocationData: {
        LocationData: this.get_scope_translation('LocationData', 'label'),
        PersonalData: this.get_scope_translation('PersonalData', 'label'),
        FirstName: this.get_scope_translation('FirstName', 'label'),
        UserName: this.get_scope_translation('FirstName', 'label'),
        LastName: this.get_scope_translation('LastName', 'label'),
        DateOfBirth: this.get_scope_translation('DateOfBirth', 'label'),
        PlaceOfBirth: this.get_scope_translation('PlaceOfBirth', 'label'),
        Gender: this.get_scope_translation('Gender', 'label'),
        CurrentAddresses: this.get_scope_translation('CurrentAddresses', 'label'),
        PreviousAddresses: this.get_scope_translation('PreviousAddresses', 'label'),
        PrincipalResidenceShort: this.get_scope_translation('PrincipalResidenceShort', 'label'),
        ResidenceShort: this.get_scope_translation('ResidenceShort', 'label'),
        Emblem: this.get_translation('App', 'Emblem', 'label'),
        ValidSince: this.get_scope_translation('ValidSince', 'label'),
      },
      feedbackLabels: {
        Name: this.get_scope_translation('Name', 'label'),
        NameRequired: this.get_scope_translation('NameRequired', 'label'),
        Message: this.get_scope_translation('Message', 'label'),
        MessageRequired: this.get_scope_translation('MessageRequired', 'label'),
        SettlementCheck: this.get_scope_translation('SettlementCheck', 'label'),
        PreviousPageVisited: this.get_scope_translation('PreviousPageVisited', 'label'),
        ContactFormTitle: this.get_scope_translation('ContactFormTitle', 'label'),
        InvalidEmail: this.get_scope_translation('InvalidEmail', 'label'),
        EmailRequired: this.get_scope_translation('EmailRequired', 'label'),
        Email: this.get_scope_translation('Email', 'label'),
        Telephone: this.get_scope_translation('Telephone', 'label'),
        TelephoneRequired: this.get_scope_translation('TelephoneRequired', 'label'),
        SubmitButtonText: this.get_scope_translation('SubmitButtonText', 'label'),
        SuccessMessage: this.get_scope_translation('SuccessMessage', 'label'),
        feedbackSuccess: 'Senden Sie Ihr Feedback erfolgreich',
        EmailSendSuccess: 'Senden Sie Ihr Email erfolgreich',
        IncorrectPhoneNumber: this.get_scope_translation('IncorrectPhoneNumber', 'label'),
      },
      forgotPasswordLabels: {
        EmailPlaceholder: this.get_scope_translation('EmailPlaceholder', 'label'),
        PasswordPlaceholder: this.get_scope_translation('PasswordPlaceholder', 'label'),
        ConfirmPasswordPlaceholder: this.get_scope_translation('ConfirmPasswordPlaceholder', 'label'),
        ConfirmPasswordRequired: this.get_scope_translation('ConfirmPasswordRequired', 'label'),
        SendEmailButtonText: this.get_scope_translation('SendEmailButtonText', 'label'),
        PasswordRequired: this.get_scope_translation('PasswordRequired', 'label'),
        ConfirmPasswordNotMatched: this.get_scope_translation('ConfirmPasswordNotMatched', 'label'),
        InvalidEmail: this.get_scope_translation('InvalidEmail', 'label'),
        EmailRequired: this.get_scope_translation('EmailRequired', 'label'),
        ConfirmFPTitle: this.get_scope_translation('ConfirmFPTitle', 'label'),
        ConfirmFPDescription: this.get_scope_translation('ConfirmFPDescription', 'label'),
        ChangePasswordButtonText: this.get_scope_translation('ChangePasswordButtonText', 'label'),
        EmailSendSuccessMessage:
          'Bitte bestätigen Sie Ihre E-Mail-Adresse über den Link in der Mail, die soeben an Sie versendet wurde.',
        SuccessPasswordReset: 'Passwort erfolgreich zurücksetzen',
      },
      resetPasswordLabels: {
        OldPasswordPlaceholder: this.get_scope_translation('OldPasswordPlaceholder', 'label'),
        OldPasswordRequired: this.get_scope_translation('OldPasswordRequired', 'label'),
        PasswordPlaceholder: this.get_scope_translation('PasswordPlaceholder', 'label'),
        PasswordRequired: this.get_scope_translation('PasswordRequired', 'label'),
        ConfirmPasswordPlaceholder: this.get_scope_translation('ConfirmPasswordPlaceholder', 'label'),
        ConfirmPasswordRequired: this.get_scope_translation('ConfirmPasswordRequired', 'label'),
        ConfirmPasswordNotMatched: this.get_scope_translation('ConfirmPasswordNotMatched', 'label'),
        ChangePasswordButtonText: this.get_scope_translation('ChangePasswordButtonText', 'label'),
        SuccessPasswordReset: this.get_scope_translation('Passwort erfolgreich aktualisiert', 'label'),
        IncorrectOldPassword: this.get_scope_translation('Falsches aktuelles Passwort', 'label'),
      },
      activationCodeMessage: {
        ActivationCodePlaceholder: this.get_scope_translation('Aktivierungscode', 'label'),
        ActivationButtonText: this.get_scope_translation('Aktivieren Sie', 'label'),
        ActivationCodeRequired: this.get_scope_translation('Aktivierungscode erforderlich', 'label'),
        SuccessMessage: this.get_scope_translation('Ihr Konto wurde erfolgreich aktiviert', 'label'),
        ErrorMessage: this.get_scope_translation('ActivationCodeMessage', 'label'),
        ErrorMessageLimitExceed: this.get_scope_translation('Fehlgeschlagener Versuch Limit überschritten', 'label'),
      },
    };
  },
  mounted: function() {
    this.appLabels = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'App');
    this.feedbackLabels = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'Feedback');
    this.forgotPasswordLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      'ForgotPassword'
    );
    this.resetPasswordLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      'ResetPassword'
    );
    this.signIn = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'SignIn');
    this.signUp = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'SignUp');
    this.LocationData = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'LocationData');
    this.activationCodeMessage = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      'ActivationCodeMessage'
    );
  },
};
