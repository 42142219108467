import { httpservice } from './httpService';
import { constants } from '../constants/constant';
import Vue from 'vue';

export function messages() {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}users/${userUUID}/${constants.API.COUNTMESSAGES}`)
    .then(function(response) {
      return response.data;
     
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function messageDetail({messageid}) {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}users/${userUUID}/${constants.API.MESSAGE}/${messageid}`)
    .then(function(response) {
      return response.data;
     
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function messageList(isArchivepage) {
  let userUUID = Vue.vclGet('user_uuid', '');
  let url = `${constants.API_URL}users/${userUUID}/${constants.API.MESSAGES}/0`;
  if(isArchivepage) {
    url = `${constants.API_URL}users/${userUUID}/${constants.API.MESSAGES}/1`
  }
  return httpservice()
    .get(url)
    .then(function(response) {
      return response.data;     
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function readMessage({messageid}) {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .put(`${constants.API_URL}users/${userUUID}/${constants.API.MESSAGE}/${messageid}/read`)
    .then(function(response) {
      return response.data;
     
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function attachmenturl(detail) {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}users/${userUUID}/${constants.API.MESSAGE}/${detail.messageid}/${constants.API.ATTACHMENT}/${detail.fileuuid}`)
    .then(function(response) {
      return response.data;    
    })
    .catch(function(error) {
      return error.response.data;
    });
}