import config from '../../config.json';
export const constants = {
  API_URL: process.env.VUE_APP_BASE_URL + '/v1/',
  LOCALIZATION_LANGUAGE: config.localization.language,
  API: {
    INITIALIZE: 'initialize',
    SIGNIN: 'signin',
    BOOKINGS: 'bookings',
    COUNTMESSAGES:'messagecount',
    MESSAGE:'message',
    MESSAGES:'messages',
    ATTACHMENT:'Attachment',
    FORGET_PASSWORD: 'users/forgot-password',
    FEEDBACK: 'send-feedback',
    FEEDBACK_EMAIL: 'sendemail',
    SIGNUP_EID: 'https://eid.oesterreich.gv.at/auth/idp/profile/oidc/authorize?response_type=code&client_id=https%3A%2F%2Fkaerstin.at&redirect_uri=https%3A%2F%2Fkaerstin.at%2Fegp&scope=openid+profile+eid',
    SIGNUP_EID_MANDATE: ''
  },
  PAGES: {
    RESET_PASSWORD: 'pages/reset-password',
    USER_ACTIVATION: 'pages/useractivation',
    NOT_FOUND: 'pages/404',
  },
};
