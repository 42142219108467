import { httpservice } from './httpService';
import { constants } from '../constants/constant';
import Vue from 'vue';

export function bookings() {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}users/${userUUID}/${constants.API.BOOKINGS}`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
