<template>
  <div :role="this.labels.Notifications" :aria-label="this.labels.Notifications" class="vgs-notifications" v-bind:class="mode">
    <Message v-for="item in this.$root.get_notifications" :key="item.Uuid" mode="alert" :headline="item.Title">{{ item.Message }}</Message>
  </div>
</template>

<script>

import { vcm } from '@/mixins/vcm'
import Message from '@/structure/Message.vue'

export default {
  mixins: [vcm],  
  name: 'Notifications',
  components: {
    Message
  },  
  props: {
    mode: String
  },
  data() {
    return {
      labels: {
        Notifications: this.get_translation('App','Notifications','label')
      }
    };
  },  
}
</script>

<style scoped>

</style>
