<template>
  <div class="vgs-message" :class="mode">
    <div class="icon"><IconSet icon="alert" state=""></IconSet></div>
    <div class="headline">{{ this.headline }}</div>
    <div class="content"><slot /></div>
  </div>
</template>

<script>
import IconSet from "@/structure/IconSet.vue";

export default {
  name: "Message",
  components: {
    IconSet,
  },
  props: {
    mode: { default: "" },
    headline: String,
  },
};
</script>

<style scoped>
</style>
