<template>
<Page mode="notfound">
    <div class="error">
        <div class="error-banner text-center">
            <router-link to="/"><img v-bind:src="get_asset_image('logo_dk.png')" :alt="labels.PlattformLogo" width="" /></router-link>
            <h3>{{labels.PortalName}}</h3>
        </div>
        <div class="error-middle-content">
        <div class="container"> 
        <h1 class="text-center">{{Title}}
          <a href="/">{{labels.AppName}}</a></h1>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="error-middle-box light-pink">
                    <p v-html="this.Section1"></p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="error-middle-box light-blue">
                    <p v-html="this.Section2"></p>
                </div>
            </div>
        </div>
        </div>
         </div>
    </div>
</Page>
</template>

<script>
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import Page from '@/structure/Page.vue';
import { getPageContent } from '@/services/pageContentService';


export default {
  mixins: [vcm,vvm],
  name: 'Error',
  components:{
      Page
  },
  data() {
    return {
        Title : null,
        Section1 : null,
        Section2 : null,
        labels : {
            AppName : "kaerstin.at",
            PortalName: "E-Government-Portal"
        }
    };
  },
  async created() {
    let pageContent = await getPageContent('404');
    if(pageContent.responseCode === '200') {
      this.Title  = pageContent.response.Title;
      this.Section1  = pageContent.response.Section1;
      this.Section2  = pageContent.response.Section2;
    }
    const FiledLabels = this.$root.get_label_fields(`${this.$root.config.localization.language}`,"NotFound");
    Object.assign(this.labels, FiledLabels);
  },
 
}

</script>

<style scoped>
.error-banner h3 {
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 8.5px;
}
.error-banner {
    position: relative;
    background-color: #FFDE00;
    padding: 21px 10px 20.5px;
    color: #000000;
    overflow: hidden;
}
.error-middle-content {
    padding: 66.5px 0;
}

.error-middle-content h1 {
    font-size: 30px;
    color: #000;
    width: 40%;
    margin: 0 auto 25px;
    line-height: 40px;
}
.error-middle-content h1 a {
    color: $primaryColor;
}
.error-middle-content a{
  color: $primaryColor;
}
.light-pink{
  background-color: #fde8ed;
}
.error-middle-box h5 {
    font-size: 16px;
    font-weight: bold !important;
}
.error-middle-box p {
    font-size: 16px;
}
.error-middle-box a.error-link {
    font-size: 16px;
    font-weight: bold;
}
.light-blue{
  background-color: #f2f2f2;
}
.error-middle-box {
    padding: 30px 14px;
    text-align: center;
}
.error-banner::after {
    content: "";
    position: absolute;
    background-image: url("../assets/notfound-image.png");
    height: 100%;
    width: 100%;
    top: 23px;
    left: 0;
    background-repeat: no-repeat;
}
.error-banner::before {
    content: "";
    position: absolute;
    background-image: url("../assets/notfound-image.png");
    height: 110%;
    width: 100%;
    bottom: -50px;
    right: -52px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 23%;
}

 .vgs-footer.menu.on {
    width: 100%;
    left: 0;
    right: 0;
}
@media only screen and (max-width: 767px){
  .error-middle-content h1 {
    width: 100%;
  }
  .error-middle-box{
    margin-bottom: 30px;
  }
}
</style>