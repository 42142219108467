import { sendFeedback, sendFeedbackEmail } from '../../services/settingService';
export default {
  state: {
    prevRoute: localStorage.getItem('prevPath') ? JSON.parse(localStorage.getItem('prevPath')) : null,
  },
  getters: {},
  actions: {
    // eslint-disable-next-line
    async sendFeedbackAction({ commit }, feedbackObj) {
      return sendFeedback(feedbackObj).then((response) => {
        return response;
      });
    },
    // eslint-disable-next-line
    async sendFeedbackEmailAction({ commit }, email) {
      return sendFeedbackEmail(email).then((response) => {
        return response;
      });
    },
  },
  mutations: {
    SET_PREV_ROUTE(state, path) {
      let prevPath = localStorage.getItem('prevPath') && localStorage.getItem('prevPath').split('/')[1];
      if (!prevPath && prevPath !== '') {
        let fullPath = window.location.origin + path;
        state.prevRoute = fullPath;
        localStorage.setItem('prevPath', JSON.stringify(fullPath));
      }
    },
  },
};
