import { httpservice } from './httpService';
import { constants } from '../constants/constant';
import Vue from 'vue';

export function getUser() {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}users/${userUUID}`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function getUserSetting() {
  //let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .get(`${constants.API_URL}pages/usersetting?language=de`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function setUserState({stateName}) {
  let useruuid  = Vue.vclGet('user_uuid', '');
  return httpservice()
    .post(`${constants.API_URL}users/${useruuid}/State?stateName=${stateName}`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function sendActivationCode({TypeName, Code}) {
  let userUUID = Vue.vclGet('user_uuid', '');
  console.log({TypeName, Code, userUUID});
  return httpservice()
    .post(`${constants.API_URL}users/${userUUID}/doubleoptin`, {TypeName, Code})
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function sendFPEmail({ Email }) {
  return httpservice()
    .put(`${constants.API_URL}${constants.API.FORGET_PASSWORD}`, { Email })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function setupPassword({ userUUID, Password }) {
  return httpservice()
    .put(`${constants.API_URL}users/${userUUID}/password-setup`, { userUUID, Password })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function changePassword({ OldPassword, NewPassword }) {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .put(`${constants.API_URL}users/${userUUID}/change-password`, { OldPassword, NewPassword })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function sendFeedback({ Message, Name, Contact, InformMatter, LastPageVisited , UUID}) {
  return httpservice()
    .post(`${constants.API_URL}${constants.API.FEEDBACK}/${UUID}`, { Message, Name, Contact, InformMatter, LastPageVisited })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

export function sendFeedbackEmail(email) {
  return httpservice()
    .post(`${constants.API_URL}${constants.API.FEEDBACK_EMAIL}/${email}`, { emailaddress: email })
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
export function deleteUserNotification() {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .post(`${constants.API_URL}users/${userUUID}/deleteusernotification`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
export function deleteUser() {
  let userUUID = Vue.vclGet('user_uuid', '');
  return httpservice()
    .delete(`${constants.API_URL}users/delete/${userUUID}`)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error.response.data;
    });
}

