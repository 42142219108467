<template>
    <footer class="vgs-footer text-center" v-bind:class="this.mode + ' ' + this.$root.get_menu_state">
      <div class="vgs-drawer" :class="get_drawer_class">
        <div class="vdr-title">{{ labels.ParticipatingPartners }}</div>
        <div v-for="item in sourcePartnerList" :key="item.ContactEMail" class="vgs-gray" @click="open_partner_area()" :title="item.Name">
          <div class="vgs-section" >
            <div class="vsn-column space">
              <img :src="get_asset_image('emblems/' + item.Emblem)" alt="Stadt Villach" />
            </div>
            <div class="vsn-column space">
              {{ item.Name }}
            </div>
          </div>
        </div>
        
        <div class="vgs-close-btn" @click="toggle_partner_area()"><b-icon icon="chevron-down"></b-icon></div>
        </div>
      <div class="vgs-fade"></div>
      <div class="vgs-tray last">
        <div class="vgs-left text-left space">
          
          <div class="vgs-section">
            <div class="vsn-column">
              <div>
                {{ labels.DevelopmentPartner }} 
              </div>
              <div class="d-flex">
                <a :href="sourceInfo.Url" target="_blank" :aria-label="labels.DevelopmentPartnerWebsite">
                  <img :aria-label="labels.DevelopmentPartnerLogo" v-bind:src="this.get_logo" :alt="labels.DevelopmentPartnerLogo" width="" />
                </a> 
                <div v-if="this.check_responsive_marker('excluding',['phone'])" class="vsn-column indent">
                  <span  v-for="item in sourcePartnerList" :key="item.ContactEMail" class="vgs-gray click" @click="open_partner_area()" :title="item.Name">
                    <img :src="get_asset_image('emblems/' + item.Emblem)" alt="Stadt Villach" />
                  </span>
              </div>
              </div>
            </div>            
          </div>  
        </div>
        <div class="vgs-right text-right">
          <MetaLinks mode="footer" />
          <div class="vgs-copyright" v-html="get_copyright('')"></div>
        </div>
      </div>
    </footer>
</template>

<script>

import MetaLinks from '@/structure/MetaLinks.vue'
import { vcm } from '@/mixins/vcm'

export default {
  mixins: [vcm],
  name: 'Footer',
  components: {
    MetaLinks
  },
  props: {
      mode: String
  },
  created : function() {
    window.addEventListener("resize", this.windowResizeHandler);
  },
  data() {
    return {
      isMobile: false,
      labels: {
        DevelopmentPartner: this.get_translation('App','DevelopmentPartner','label'),
        DevelopmentPartnerWebsite: this.get_translation('App','DevelopmentPartnerWebsite','label'),
        DevelopmentPartnerLogo: this.get_translation('App','DevelopmentPartnerLogo','label'),
        ParticipatingPartners: this.get_translation('App','ParticipatingPartners','label'),
      },
      partnerAreaOpen: false,
      sourceInfo: {        
        Url: this.$root.get_source_info('Url','')
      }
    };
  },
  computed: {
    get_drawer_class: function(){
        var rv = ''
        if (this.partnerAreaOpen) {
            rv += ' vgs-open '
        }
        return rv
    },
    get_logo: function(){
      var logo = this.$root.get_source_info('Logo','logo_villach.png')
      return this.get_asset_image(logo)
    },
    sourcePartnerList() {
      return this.$store.state.initialize.sourcePartnerList;
    }

  },
  mounted() {
    this.windowResizeHandler();  
  },
  destroyed() {
  window.removeEventListener("resize", this.windowResizeHandler);
  },
  methods: {
    toggle_partner_area(){
      if (this.partnerAreaOpen) {
          this.partnerAreaOpen = false
      } else {
          this.partnerAreaOpen = true
      }
    },
    open_partner_area(){
      this.partnerAreaOpen = true
    },

   windowResizeHandler() {
    // your code for handling resize...
    this.isMobile = window.innerWidth < 768 ? true : false;
    },
  }
}
</script>

<style scoped >

</style>
