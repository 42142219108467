<template>
  <div role="navigation" aria-label="Meta Navigation" class="vgs-menu-links" :class="mode">    
    <span v-for="item in metaLinks" :key="item.Label">
        <a :href="item.Url">{{item.Label}}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MetaLinks',
  props: {
    mode: String
  },
  computed: {
    metaLinks() {
      return this.$store.state.initialize.metaLinks;
    },
    sourcePartnerList() {
      return this.$store.state.initialize.sourcePartnerList;
    }
  }
}
</script>

<style scoped>

</style>
