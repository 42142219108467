<template>
  <div
    v-if="this.mode == 'jacket'"
    class="vgs-page"
    :id="isStartpage ? 'startpage' : ''"
    :class="getClass"
  >
    <Jacket />
    <div class="vgs-jacket-content p-5" :class="this.$root.get_menu_state">
      <div role="main" class="position-relative text-center">
        <slot />
        <br /><br /><br />
      </div>
    </div>
    <Footer mode="jacket" />
  </div>
  <div
    v-else
    class="vgs-page"
    :id="isStartpage ? 'startpage' : ''"
    :class="getClass"
  >
    <Menu v-if="this.mode != 'notfound'" />
    <div
      :class="
        this.mode != 'notfound'
          ? `${this.$root.get_menu_state} vgs-page-content`
          : this.$root.get_menu_state
      "
    >
      <div role="main">
        <slot />
        <br /><br /><br />
      </div>
      <Statebar :key="statebar_refresh" v-if="this.mode != 'notfound'" />
      <Footer mode="menu" />
    </div>
  </div>
</template>

<script>
import Jacket from "@/structure/Jacket.vue";
import Menu from "@/structure/Menu.vue";
import Statebar from "@/structure/Statebar.vue";
import Footer from "@/structure/Footer.vue";

import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";

export default {
  mixins: [vcm, vvm],
  name: "Page",
  components: {
    Footer,
    Jacket,
    Menu,
    Statebar,
  },
  props: {
    mode: String,
  },
  data() {
    return {
      getClass: {},
      isStartpage: false,
    };
  },
  created() {
    window.addEventListener("resize", this.windowResize);
  },
  mounted() {
    this.windowResize();
  },
  updated() {
    if (this.$route.name == "startpage") {
      this.isStartpage = true;
    } else {
      this.isStartpage = false;
    }
  },

  methods: {
    windowResize() {
      let windowWidth = window.innerWidth;
      var rv = "";
      this.set_responsive_marker(windowWidth);
      rv = this.get_responsive_marker_class();
      this.getClass = rv;
    },
  },
};
</script>

<style scoped>
</style>

