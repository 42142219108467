<template>
<div class="vgs-icon-set">
  <component
    :is="iconComponent"
    role="img"
    class="inline-block fill-current"
  />
</div>
</template>

<script>
import map from './icons/MapIcon.vue';
import mobile from './icons/MobileIcon.vue';
import fax from './icons/FaxIcon.vue';
import email from './icons/EmailIcon.vue';
export default {
  props: {
    name: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      icons: {}
    }
  },

  created () {
    this.icons = {
      map,
      mobile,
      fax,
      email
   }
  },

  computed: {
    iconComponent() {
      return this.icons[this.name]
    }
  }
}
</script>