<template>
  <div role="region" aria-label="Header" class="vcs-statebar" v-bind:class="this.$root.get_menu_state">
    <div class="vcs-burger" v-if="this.check_responsive_marker('including',['phone'])">     
      <div role="region" aria-label="MainMenu" class="">
        <div class="dropdown vcs-dropdown-wide" v-bind:class="get_main_menu_state" v-click-outside="outside_main_menu">
          <a :aria-label="appLabels['Notifications']" href="#" class="vcs-burger-set" data-toggle="dropdown" @click="toggle_main_menu">            
            <img v-if="this.main_menu_state=='show'" v-bind:src="get_asset_image('burger_close.png')" alt="" class="" />
            <img v-else v-bind:src="get_asset_image('burger.png')" alt="" class="" />
          </a>
          <div class="dropdown-menu p-0 dropdown-menu-left vcs-overlay-menu"  v-bind:class="get_main_menu_state" v-bind:aria-expanded="get_main_menu_expanded">
            <MenuNavigation mode="overlay-menu"></MenuNavigation>
          </div>
        </div>
      </div>     
    </div>
        
    
    <div class="vcs-logo" v-if="this.check_responsive_marker('including',['phone'])">
      <a href="/"> <img v-bind:src="get_asset_image('logo_dk_top.png')" :alt="appLabels['PlattformLogoSmall']" class="" /></a>
    </div>
    

    <div class="vcs-bar">
      <div role="region" aria-label="Notifications" class="vcs-bar-element">
        <div class="dropdown vcs-menu-set" v-bind:class="get_notifications_state" v-click-outside="outside_notifications">
          <a :aria-label="appLabels['Notifications']" href="#" class="vcs-notification-bell" data-toggle="dropdown" @click="toggle_notifications">
          <div>
          <div class="vcs-unread"><div class="vcu-shockwave"></div><div class="vcu-icon"><IconSet icon="reminder"></IconSet></div></div>
          <div class="vcs-read"><IconSet icon="notification"></IconSet></div>
          </div>          
          </a>

          <div class="dropdown-menu p-0 dropdown-menu-right"  v-bind:class="get_notifications_state" v-bind:aria-expanded="get_notifications_expanded">
            <NotificationItem v-for="item in this.$root.get_notification_menu_items" :key="item.Uuid" v-bind:item="item" v-bind:show="true" />
            <div v-if="this.$root.notifications.length==0" class="vcs-message">{{ appLabels['NoActiveNotifications'] }}</div>            
          </div>
        </div>      
      </div>

      <div role="region" aria-label="User Menu" class="vcs-bar-element">
        <div class="dropdown vcs-menu-set" v-bind:class="get_user_menu_state" v-click-outside="outside_user_menu">
          <a href="#" class="vcs-user-menu text-decoration-none" data-toggle="dropdown" @click="toggle_user_menu">
            <div v-if="this.check_responsive_marker('excluding',['phone'])">
              <span v-html="get_user_name" class="vgs-link-highlight"></span>
              <span>
              <span class="vcs-down vgs-link-highlight"><b-icon icon="arrow-down-circle"></b-icon></span>
              <span class="vcs-up vgs-link-highlight"><b-icon icon="arrow-up-circle"></b-icon></span>
              </span>
            </div>
            <div v-if="this.check_responsive_marker('including',['phone'])" class="vcs-user-menu-icon">
              <img v-bind:src="get_asset_image('icons/temp_user_icon.png')" alt="" class="" />
            </div>
          </a>

          <div class="dropdown-menu p-0 dropdown-menu-right" v-bind:class="get_user_menu_state" v-bind:aria-expanded="get_user_menu_expanded">
            <div v-for="item in this.$root.get_active_user_menu_items" :key="item.view">
              <router-link v-if="item.action=='route' && item.action !== 'sign.out'" :to="{ name: item.route}" class="text-decoration-none dropdown-item p-2">
                <b-icon v-bind:icon="item.icon"></b-icon><span>&nbsp;</span><span>{{ appLabels[item.name] }}</span>
              </router-link>              
              <a v-else  href="/anmeldung" class="text-decoration-none dropdown-item p-2" @click="user_menu_handler(item)" >                
                <b-icon :icon="item.icon"></b-icon><span>&nbsp;</span><span>{{ appLabels[item.name] }}</span>
              </a>                        
            </div>
          </div>
        </div>                 
      </div>
    </div>

  </div>    
</template>

<script>
// eslint-disable-next-line
import Vue from 'vue'
import NotificationItem from '@/structure/NotificationItem.vue'
import IconSet from '@/structure/IconSet.vue'
import MenuNavigation from '@/structure/MenuNavigation.vue'
import { vcm } from '@/mixins/vcm'
import { customLabels } from '@/mixins/customLabels'
import ClickOutside from 'vue-click-outside'

export default {
  mixins: [vcm, customLabels],
  components: {
    NotificationItem, IconSet, MenuNavigation
  },
  name: 'Statebar',
  data() {
    return {      
      user_menu_state: '',
      notifications_state: '',
      main_menu_state: '',
    };
  },
  computed: {      
     get_user_name: function(){
      return Vue.vclGet('user_name','User')     
    },
    get_user_menu_state: function(){
      return this.user_menu_state
    },
    get_user_menu_expanded: function(){
      if (this.user_menu_state == 'show') {
        return 'true'
      } else {
        return 'false'
      }
    },
    get_notifications_state: function(){
      var rv = ''
      rv += this.notifications_state
      if (this.$root.notifications.length > 0) {
        rv += ' unread' // not strictly true... 
      }
      return rv
    },
    get_notifications_expanded: function(){
      if (this.notifications_state == 'show') {
        return 'true'
      } else {
        return 'false'
      }
    },    
    get_main_menu_state: function(){
      var rv = ''
      rv += this.main_menu_state
      return rv
    },
    get_main_menu_expanded: function(){
      if (this.main_menu_state == 'show') {
        return 'true'
      } else {
        return 'false'
      }
    }
  },
  methods: {   
    toggle_user_menu: function(){
       if (this.user_menu_state == '') {
         this.user_menu_state = 'show'
       } else {
         this.user_menu_state = ''
       }
    },
    outside_user_menu: function () {
      if (this.user_menu_state == 'show') {
        this.user_menu_state = ''
      }
    },
    toggle_notifications: function(){
       if (this.notifications_state == '') {
         this.notifications_state = 'show'
       } else {
         this.notifications_state = ''
       }
    },
    outside_notifications: function () {
      if (this.notifications_state == 'show') {
        this.notifications_state = ''
      }
    },    
    toggle_main_menu: function(){
       if (this.main_menu_state == '') {
         this.main_menu_state = 'show'
       } else {
         this.main_menu_state = ''
       }
    },
    outside_main_menu: function () {
      if (this.main_menu_state == 'show') {
        this.main_menu_state = ''
      }
    },    
    user_menu_handler(item) {
      switch (item.action) {
        case 'sign.out':
          this.$root.sign_out();
          break
        case 'demo.talk.to.me':
          this.$root.add_alert('Hi!','What up?','emoji-smile')
          break
      }
    },
    notifications_handler(item) {
      Vue.vclDeploy('item',item)
    }
  },
  directives: {
    ClickOutside
  },
  events: {
   
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";

/*navbar fixed-top navbar-expand-lg  */
.vcs-statebar { position: fixed; top: 0px; left: 0px; min-height: 3rem; background-color: #ffffff; z-index: 9999; }
.vcs-statebar:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

.vcs-burger { position: absolute; top: 0px; left: 0px; width: 100%; background: $highlightColor; }
.vcs-burger-set { background-color: $menuBGColor; display: inline-block; padding: 0.80rem; }
.vcs-logo { position: absolute; top: 0px; left: 4rem; }
.vcs-logo img { height: 2.5rem; margin-top: 0.3rem; }

.vcs-bar { position: absolute; top: 0px; right: 0px; min-height: 3rem; }
.vcs-bar:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.vcs-bar-element { float: left; padding: 0.5rem; margin-top: 0.3rem; }

.vcs-overlay-menu { width: 100%; margin-top: 0px !important; border-top: 2px solid $menuBGColor; background-color: $menuBGColor;
border-radius: 0px !important;
}

.vcs-dropdown-wide { width: 100%; }

.vcs-user-menu-icon { margin-right: 1rem; }
.vcs-user-menu-icon img { width: 22px; }

.vcs-notification-bell .vcu-icon { border-bottom: 1px solid transparent; }
.vcs-notification-bell:focus .vcu-icon { outline: none; border-top: none; border-left: none; border-right: none; border-bottom: 1px solid $focusHighlightColor; }




.vcs-notification-center-link:focus span { outline: none; border-top: none; border-left: none; border-right: none; border-bottom: 1px solid $focusHighlightColor; }
.vcs-notification-center-link span .b-icon { margin-left: 0.5rem; } 

a.vcs-user-menu:focus  { outline: none; border-top: none; border-left: none; border-right: none; border-bottom: 1px solid $focusHighlightColor; }

.dropdown-menu { margin-top: 8px; box-shadow: 2px 4px 8px rgba(0,0,0,0.2);background-color:$menuBGColor; }
.vcs-menu-set .dropdown-item:hover { background-color: $menuBGColor; color: $formBorderColor; }
.vcs-menu-set .dropdown-item:focus { background-color: $menuBGColor; color: $formBorderColor; }

.vcs-notification-center { color: $highlightFontColor; text-align: right; white-space: nowrap; }


.vcs-message { min-width: 400px; padding: 12px 18px; }

.vcs-menu-set { float: right; margin-left: 1rem; color: $appFontColor; }

.vcs-menu-set span { padding-right: 9px;}
.vcs-menu-set a { color: $primaryNavColor; }
.vcs-menu-set a:hover { color: $primaryNavColor; text-decoration: none; }




.vcs-menu-set.dropdown { float: left;}
.vcs-menu-set.dropdown .vcs-up { display: none; }
.vcs-menu-set.dropdown.show .vcs-up { display: inline-block; }
.vcs-menu-set.dropdown .vcs-down { display: inline-block; }
.vcs-menu-set.dropdown.show .vcs-down { display: none; }

.vcs-menu-set.dropdown .vcs-unread { display: none; }
.vcs-menu-set.dropdown.unread .vcs-unread { position:relative; display: inline-block; color: $highlightFontColor;  }
.vcs-menu-set.dropdown.unread .vcs-unread .vcu-icon { position: relative; }

.vcs-menu-set.dropdown.unread .vcs-unread .vcu-shockwave { position: absolute; top: -28px; left: -10.5px; z-index: -1000; width: 40px; height: 40px; margin: 20px auto;
  background:
    radial-gradient(transparent 21%,$waveColor 21%,$waveColor 22%,transparent 28%),
    radial-gradient(transparent 36%,$waveColor 40%,$waveColor 42%,transparent 50%),
    radial-gradient(transparent 60%,$waveColor 65%,$waveColor 66%,transparent 70%);
  background-size:0 0,0 0,0 0;
  background-position:center;
  background-repeat:no-repeat;
  animation-name:vcs-wave-lines;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 3;
}
@keyframes vcs-wave-lines{
  0% {
    background-size:0 0,0 0,0 0;
  }
  25% {
    background-size:0 0,0 0,30% 30%;
  }
  50% {
    background-size:10% 10%,40% 40%,60% 60%;
    opacity: 0.8;
  }
  75% {
    background-size:100% 100%,100% 100%,100% 100%;
    opacity: 0.8;
  }
  100% {
    background-size:50% 50%,50% 50%,50% 50%;
    opacity: 0.2;
  }
}



.vcs-menu-set.dropdown .vcs-read { display: inline-block; }
.vcs-menu-set.dropdown.unread .vcs-read { display: none; }





</style>
