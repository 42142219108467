<template>
  <div role="region" aria-label="alerts" class="vcs-alerts" :class="position">    
    <AlertItem v-for="item in this.$root.alerts" :key="item.index" :title="item.name" :description="item.description" :icon="item.icon" />
  </div>
</template>

<script>
import AlertItem from '@/components/AlertItem.vue'

export default {
    name: 'Alerts',
    props: {
        position: String
    },
    components: {
        AlertItem
    },
    data() {
      return {
        dismissSecs: 10,
        dismissCountDown: 10,
        showDismissibleAlert: false
      }
    },
    mounted: function () {    
    
    

    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    },
    created: function () {
        /*
        if (this.$root.alerts.length > 0) {
            var intervalBase = 500
            var intervalStep = 200
            var interval = intervalBase + intervalStep
            for (var i = 0; i < this.$root.alerts.length; i++) {
                interval = (interval + intervalStep)
                var alertObj = this.$root.alerts[i]
                setTimeout(function () {                     
                    var pList = document.getElementsByClassName('item_' + alertObj.index)
                    if (pList) {
                        if (pList.length > 0) {
                            pList[0].classList.add('hide')
                        }
                    }
                 }, interval)
            }
        }
        */
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-alerts { position: fixed; }
.vcs-alerts.bottom-right { bottom: 24px; right: 32px;  }
.vcs-alerts .vca-item { border: 1px solid #d7d7d7; background-color: $alertBGColor; padding: 12px 18px 6px 18px; min-width: 280px; box-shadow: 2px 4px 8px rgba(0,0,0,0.1); margin-top: 24px; 
-webkit-animation: fadeinout 4s linear forwards;
animation: fadeinout 4s linear forwards;
}
.vcs-alerts .vca-item b { font-weight: 600; }
.vcs-alerts .vca-item p { font-size: 14px; }

.vcs-alerts .vca-item.hide { display: none; }

@-webkit-keyframes fadeinout {
  100% { display: none; }
  0% { display: block; }
}

@keyframes fadeinout {
  100% { display: none; }
  0% { display: block; }
}

</style>
