<template>
  <Page>
    <ViewTitle :title="title + ', ' + this.username" />
    
    <div
      v-if="
        stateName === 'CodeEntry' ||
        stateName === 'CodeDispatched' ||
        stateName === 'SupportRequired' ||
        stateName === 'CodeExpired'
      "
    >
      <div class="vgs-notifications px-0" v-bind:class="mode">
        <Message :mode="mode" :headline="labels.UserNotActivatedTitle"><p v-html="p(labels.UserNotActivatedText)"></p></Message>
      </div>
    </div>
    <Notifications mode="info"></Notifications>
    <div class="p-0">
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <router-link
            :to="
              serviceGroupCollection.length && stateName==='Complete' ? AccountInfoContent.LinkTarget : ''
            "
            class="text-decoration-none link d-flex flex-column h-100 box1link"
          >
            <p class="bold mb-2">{{ AccountInfoContent.HeaderText }}</p>
            <div
              class="box box1"             
              :class="serviceGroupCollection.length && stateName === 'Complete' ? '' : 'emptybox'"
            >
              <div class="content pl-0 h-100">
                <div class="d-flex chart-parent">
                  <div class="chart-box p-0">
                    <ChartDoughnut
                      ref="booking_chart"
                      v-if="chartLoaded"
                      :chart-data="chartData"
                      class="vcs-chart"
                      :options="chartOptions"
                      :width="chartWidth"
                      :height="chartHeight"
                    ></ChartDoughnut>
                    <div class="chart-text text-center" v-if="chartLoaded">
                      <p
                        class="bold"
                        v-html="get_formatted_number(account.Sum)"
                      ></p>
                      <p class="label-spent">{{ labels.Spent }}</p>
                    </div>
                  </div>
                  <div v-if="stateName !== 'Complete'" class="px-3 d-flex align-items-center justify-content-center gap">
                       
                    <IconSet
                    v-if="chartLoaded"
                      :icon="'emptyaccount'"
                      :title="'emptyaccount'"
                      class="sli-img"
                      :fill="''"
                    ></IconSet>
                    <p
                      v-if="chartLoaded"
                      class="text-left ml-3 mb-0 mt-0 no-info"
                     
                    >{{labels.SemiRegisteredAccountInfoText}}
                    </p>
                 
                  </div>
                  <div
                    class="pl-4 booking-box"
                    v-else-if="serviceGroupCollection.length"
                  >
                    <div
                      class="content container-fluid p-0 d-flex flex-column justify-content-between content1 h-100"
                    >
                      <div class="row m-0 justify-content-between">
                        <template>
                          <div
                            class="col-lg-6 col-md-6 col-sm-12 pr-0"
                            v-for="(item, index) in thirdArray"
                            :key="index"
                            :value="item"
                            :class="index % 2 == 0 ? 'pl-0':'rightitem'"
                          >
                         
                            <div class="d-flex my-0 justify-content-end group">
                              <IconSet
                                :icon="item && item.Icon"
                                :title="item.Label"
                                class="sli-img"
                                :fill="item.Color"
                              ></IconSet>
                              <p class="mx-1 my-1 mt-2 tel">{{ item.Name }}</p>
                              <p
                                class="mx-1 my-1 mt-2 tel ml-auto category"
                                v-html="get_formatted_number(item.amount)"
                              ></p>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="row m-0 redbox ml-0">
                        <div class="d-flex my-1">
                          <p class="mx-1 my-1" v-if="account.Balance < 0">
                            {{ labels.Deposit }}
                          </p>
                          <p class="mx-1 my-1" v-else>
                            {{ labels.Arrearage }}
                          </p>
                        </div>
                        <div class="d-flex my-1 ml-auto">
                          <p
                            class="mx-1 my-1"
                            v-html="get_formatted_number(account.Balance)"
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="px-3 d-flex align-items-center justify-content-center gap" 
                    v-else
                  >
                    <IconSet
                    v-if="chartLoaded"
                      :icon="'emptyaccount'"
                      :title="'emptyaccount'"
                      class="sli-img"
                      :fill="''"
                    ></IconSet>
                    <p
                    v-if="chartLoaded"
                      class="text-left ml-3 mb-0 mt-0 no-info"
                      v-html="AccountInfoContent.NoAccountInfoText"
                    ></p>
                  </div>
                </div>
              </div>

              <div
                class="d-flex my-0 LinkText"
                v-if="serviceGroupCollection.length && stateName === 'Complete'"
              >
                <p>
                  <router-link
                    :to="AccountInfoContent.LinkTarget">{{ AccountInfoContent.LinkText }}<IconSet icon="Right" state="on"></IconSet></router-link>  
             
                </p>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-sm-12 d-flex">
          <router-link
            :to="
              (MessageCount.read > 0 ||
              MessageCount.unread > 0 ||
              MessageCount.archive > 0) && (stateName === 'Complete')
                ? MessageContent.LinkTarget
                : ''
            "
            class="text-decoration-none link d-flex flex-column flex-grow-1"
          >
            <p class="bold mb-2">{{ MessageContent.HeaderText }}</p>
            <div
              class="box d-flex flex-column justify-content-between"
              :class="
               ((MessageCount.read > 0 ||
                MessageCount.unread > 0 ||
                MessageCount.archive > 0) && stateName === 'Complete')
                  ? ''
                  : 'emptybox'
              "
            >
             <div class="content no-info h-100 align-items-center d-flex flex-column justify-content-center"
             v-if="stateName !== 'Complete'">
                
                <IconSet icon="emptymessage" state="on"></IconSet>
                <p
                  class="text-center"
                 
                >{{labels.SemiRegisteredMessageInfoText}}</p>
             
             </div>
              <div
                v-else-if="
                  MessageCount.read > 0 ||
                  MessageCount.unread > 0 ||
                  MessageCount.archive > 0
                "
              >
                <div class="content">
                  <div class="d-flex align-items-center my-1">
                    <IconSet icon="email" state="on"></IconSet>
                    <p class="mx-2 mb-0 mt-1 para-class">
                      <strong
                        >{{ MessageCount.unread }} {{ labels.Unread }}</strong
                      >
                    </p>
                  </div>
                  <div class="d-flex align-items-center my-4">
                    <IconSet icon="openemail" state="on"></IconSet>

                    <p class="mx-2 mb-0 mt-1">
                      {{ MessageCount.read }} {{labels.Read}}
                    </p>
                  </div>
                  <div class="d-flex align-items-center my-4">
                    <IconSet icon="archivelist" state="on"></IconSet>
                    <p class="mx-2 my-1">
                      {{ MessageCount.archive }} {{labels.Archiv}}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="content no-info h-100 align-items-center d-flex flex-column justify-content-center"
                v-else
              >
                <IconSet icon="emptymessage" state="on"></IconSet>
                <p
                  class="text-center"
                  v-html="MessageContent.NoMessageInfoText"
                ></p>
              </div>
              <div
                class="d-flex LinkText"
                v-if="
                  (MessageCount.read > 0 ||
                  MessageCount.unread > 0 ||
                  MessageCount.archive > 0)  && stateName === 'Complete'
                "
              >
                <p>
                  <router-link
                    :to="MessageContent.LinkTarget">{{ MessageContent.LinkText }}<IconSet icon="Right" state="on"></IconSet></router-link>  
             
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-12 d-flex flex-column">
          <router-link
            :to="
              stateName === 'Complete'
                ? AccountContent.LinkTarget
                : ''
            "
            class="text-decoration-none link d-flex flex-column flex-grow-1"
          >
            <div class="d-flex my-1 mb-2">
              <IconSet icon="accounting" state="on"></IconSet>
              <p class="bold mb-0 ml-2">{{ AccountContent.HeaderText }}</p>
            </div>
            <div class="box d-flex flex-column justify-content-between"
             :class="
                stateName === 'Complete' ? '' : 'cursor-class'
              ">
              <div class="content mt-3">
                <p v-html="AccountContent.Content"></p>
              </div>
              <div class="d-flex LinkText" v-if="stateName === 'Complete'">
                <p>
                  <router-link
                    :to="AccountContent.LinkTarget">{{ AccountContent.LinkText }}<IconSet icon="Right" state="on"></IconSet></router-link>  
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-sm-12 d-flex flex-column">
          <div class="d-flex my-1 mb-2">
            <IconSet icon="forms" state="on"></IconSet>
            <p class="bold mb-0 ml-2">{{ FormsContent.HeaderText }}</p>
          </div>
          <div class="box d-flex flex-column justify-content-between">
            <div class="content mt-3">
              <div v-html="renderedContent(FormsContent.Content)"></div>
            </div>
            <div class="d-flex LinkText">
              <p>
                 <router-link
                    :to="FormsContent.LinkTarget">{{ FormsContent.LinkText }}<IconSet icon="Right" state="on"></IconSet></router-link>              
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 d-flex flex-column">
          <router-link
            :to="
              ((PrincipleAddress!==null &&  PrincipleAddress.UserAddressTypeName) || (ResidenceAddress!==null && ResidenceAddress.UserAddressTypeName)) && stateName === 'Complete'
                ? ResidentContent.LinkTarget
                : ''
            "
            class="text-decoration-none link d-flex flex-column flex-grow-1"
          >
            <div class="d-flex my-1 mb-2">
              <IconSet icon="locationdata" state="on"></IconSet>
              <p class="bold mb-0 ml-2">{{ ResidentContent.HeaderText }}</p>
            </div>

            <div
              class="box d-flex flex-column justify-content-between"
              :class="
                ((PrincipleAddress!==null &&  PrincipleAddress.UserAddressTypeName) || (ResidenceAddress!==null && ResidenceAddress.UserAddressTypeName)) && stateName === 'Complete' ? '' : 'emptybox'
              "
            >
              <div class="content h-100">
                <div v-if="stateName !== 'Complete'"  class="h-100 no-info align-items-center d-flex flex-column justify-content-center">
                   
                  <IconSet icon="nodata" state="on"></IconSet>
                  <p class="text-center">
                    {{ labels.SemiRegisteredAdressInfoText }}
                  </p>
               
                </div>
                <div v-else-if="(PrincipleAddress!==null &&  PrincipleAddress.UserAddressTypeName) || (ResidenceAddress!==null && ResidenceAddress.UserAddressTypeName)">
                  <div v-if="PrincipleAddress!==null && PrincipleAddress.UserAddressTypeName" class="mb-3">
                    <div
                      v-if="PrincipleAddress!==null && PrincipleAddress.UserAddressTypeName"
                      
                    >
                      <div class="d-flex my-0">
                        <p class="head mt-3">
                          {{ labels.CurrentAddressDashboard }}
                        </p>
                      </div>
                      <div class="d-flex lg:my-0 my-0">
                        <img
                          :src="
                            PrincipleAddress.Emblem
                              ? get_asset_image(`emblems/${PrincipleAddress.Emblem}`)
                              : get_asset_image('emblems/villach.png')
                          "
                          alt="image-description"
                          class="ml-1 mt-1 hight"
                        />

                        <div class="ml-3">
                          <p class="mx-0 my-0">
                            {{ PrincipleAddress.Street }} {{ PrincipleAddress.StreetNumber }}
                          </p>
                          <p class="mx-0 my-0">
                            {{ PrincipleAddress.PostalCode }} {{ PrincipleAddress.City }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ResidenceAddress!=null && ResidenceAddress.UserAddressTypeName">
                    <div class="d-flex"
                     :class="
                 (PrincipleAddress!==null &&  PrincipleAddress.UserAddressTypeName)  ? 'my-0' : 'mt-3'">
                      <p class="head">{{ labels.PreviousAddressDashboard }}</p>
                    </div>
                    <div class="d-flex my-0">
                      <img
                        :src="
                          ResidenceAddress.Emblem
                            ? get_asset_image(
                                `emblems/${ResidenceAddress.Emblem}`
                              )
                            : get_asset_image('emblems/villach.png')
                        "
                        alt="image-description"
                        class="ml-1 mt-1 hight"
                      />
                      <div class="ml-3">
                        <p class="mx-0 my-0">
                          {{ ResidenceAddress.Street }}
                          {{ ResidenceAddress.StreetNumber }}
                        </p>
                        <p class="mx-0 my-0">
                          {{ ResidenceAddress.PostalCode }}
                          {{ ResidenceAddress.City }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="h-100 no-info align-items-center d-flex flex-column justify-content-center"
                  v-else
                >
                  <IconSet icon="nodata" state="on"></IconSet>
                  <p class="text-center">
                    {{ labels.NoAddressText }}
                  </p>
                </div>
              </div>
              <div
                class="d-flex LinkText"
                v-if="((PrincipleAddress!==null &&  PrincipleAddress.UserAddressTypeName) || (ResidenceAddress!==null && ResidenceAddress.UserAddressTypeName)) && stateName === 'Complete'"
              >
                <p>
                   <router-link
                    :to="ResidentContent.LinkTarget">{{ ResidentContent.LinkText }}<IconSet icon="Right" state="on"></IconSet></router-link>  
             
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <Message mode="info" :headline="labels.ContactHeadline">
      <div
        class="vgs-info-box footer"
        v-if="this.check_responsive_marker('excluding', ['phone'])"
      >
        <div class="d-flex justify-content-between mb-4">
          <div class="text-left">
            <BaseIcon name="map"></BaseIcon> {{ this.contactInfo.location }}
          </div>
          <div class="text-left">
            <BaseIcon name="mobile"></BaseIcon> {{ this.contactInfo.phone }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="text-left">
            <BaseIcon name="fax"></BaseIcon> {{ this.contactInfo.fax }}
          </div>
          <div class="text-left mr-4">
            <BaseIcon class="mr-2" name="email"></BaseIcon>
            <a :href="'mailto:' + this.contactInfo.email" class="mail-a">{{
              this.contactInfo.email
            }}</a>
          </div>
        </div>
      </div>
      <div
        class="vgs-info-box"
        v-if="this.check_responsive_marker('including', ['phone'])"
      >
        <div class="vib-tray">
          <div class="vbt-line">
            <BaseIcon name="map"></BaseIcon> {{ this.contactInfo.location }}
          </div>
          <div class="vbt-line">
            <BaseIcon name="mobile"></BaseIcon> {{ this.contactInfo.phone }}
          </div>
          <div class="vbt-line">
            <BaseIcon name="fax"></BaseIcon> {{ this.contactInfo.fax }}
          </div>
          <div class="vbt-line">
            <BaseIcon name="email"></BaseIcon>
            <a :href="'mailto:' + this.contactInfo.email">{{
              this.contactInfo.email
            }}</a>
          </div>
        </div>
      </div>
    </Message>
  </Page>
</template>

<script>
import Page from "@/structure/Page.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import Notifications from "@/structure/Notifications.vue";
import Message from "@/structure/Message.vue";
import IconSet from "@/structure/IconSet.vue";
import Vue from 'vue';
import { mapActions } from "vuex";
import BaseIcon from "@/components/BaseIcon.vue";

import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import axios from "axios";
import { getUser, getUserSetting } from "@/services/settingService";
import ChartDoughnut from "@/components/ChartDoughnut.vue";

export default {
  mixins: [vcm, vvm],
  name: "Startpage",
  components: {
    Page,
    ViewTitle,
    Message,
    Notifications,
    BaseIcon,
    IconSet,
    ChartDoughnut,
  },
  data() {
    return {
      CurrentResidence: {},
      chartWidth: 180,
      chartHeight: 180,
      account: {
        Balance: 0,
        Sum: 0,
      },
      thirdArray: [],
      chartData: {
        chartData: null,
        type: "doughnut",
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],

            weight: 1,
          },
        ],
        borderWidth: "120px",
        borderAlign: "inner",
        spacing: "250px",
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        cutoutPercentage: 80,
        weight: 5,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function (tooltipItem, data) {
              var dataset = data["datasets"][0];
              var value = dataset["data"][tooltipItem["index"]];
              var currency = value.toLocaleString("de-AT", {
                currency: "EUR",
                minimumFractionDigits: 2,
              });

              return currency + " €";
            },
          },
        },
      },
      chartLoaded: false,

      labels: {
        Introduction: this.get_scope_translation("Introduction", "label"),
        ContactHeadline: this.get_scope_translation("ContactHeadline", "label"),
        UserNotActivatedTitle: "",
        UserNotActivatedText: "",
        Arrearage: this.get_scope_translation("Arrearage", "label"),
        Deposit: this.get_scope_translation("Deposit", "label"),
        Spent: this.get_scope_translation("Spent", "label"),
        NoAddressText: this.get_scope_translation("NoAddressText", "label"),
        CurrentAddressDashboard: this.get_scope_translation(
          "CurrentAddressDashboard",
          "label"
        ),
        PreviousAddressDashboard: this.get_scope_translation(
          "PreviousAddressDashboard",
          "label"
        ),
         Read:this.get_scope_translation("Read", "label"), 
         Unread:this.get_scope_translation("Unread", "label"), 
         Archiv:this.get_scope_translation("Archiv", "label"), 
         SemiRegisteredAccountInfoText:this.get_scope_translation("SemiRegisteredAccountInfoText", "label"), 
         SemiRegisteredMessageInfoText:this.get_scope_translation("SemiRegisteredMessageInfoText", "label"), 
         SemiRegisteredAdressInfoText:this.get_scope_translation("SemiRegisteredAdressInfoText", "label"), 
      },
      contactInfo: {
        location: this.$root.get_system_setting("SystemContactLocation", ""),
        phone: this.$root.get_system_setting("SystemContactPhone", ""),
        fax: this.$root.get_system_setting("SystemContactFax", ""),
        email: this.$root.get_system_setting("SystemContactEMail", ""),
      },
      AccountInfoContent: {
        HeaderText: "",
        NoAccountInfoText: "",
        Content: "",
        LinkText: "",
        LinkTarget: "",
      },
      MessageContent: {
        HeaderText: "",
        NoMessageInfoText: "",
        Content: "",
        LinkText: "",
        LinkTarget: "",
      },
      AccountContent: {
        HeaderText: "",
        Content: "",
        LinkText: "",
        LinkTarget: "",
      },
      FormsContent: {
        HeaderText: "",
        Content: "",
        LinkText: "",
        LinkTarget: "",
      },
      ResidentContent: {
        HeaderText: "",
        Content: "",
        LinkText: "",
        LinkTarget: "",
      },
      title: this.$root.get_user_salutation,
      username: localStorage.getItem("vcl_base_user_name"),
      mode: "alert",
      stateName: this.$store.getters["setting/getUpdatedActivationState"],
      Description: null,
    };
  },
  computed: {
    
    PrincipleAddress(){
      return { ...this.$store.state.setting.PrincipleAddress };
    },
     ResidenceAddress(){
      return { ...this.$store.state.setting.ResidenceAddress };
    },
    MessageCount() {
      return { ...this.$store.state.message.MessageCount };
    },
    serviceGroupCollection() {
      return this.$store.state.booking.serviceGroupCollections;
    },

    bookingList() {
      return this.$store.state.booking.bookingList;
    },
  },
    beforeMount() {
      localStorage.removeItem("isDeletePageAccessible");
    try {
      getUser().then((result) => {
        if (
          result.responseCode === "200" &&
          result.responseMessage === "User data"
        ) {
          this.stateName = result.response.User.StateName;
          var userStateName = Vue.vclGet('user_db_stateName','');
          if(this.stateName !== userStateName)
          Vue.vclUpdate('user_db_stateName', this.stateName);
          try {
            getUserSetting().then((result) => {
              if (result.responseCode === "200" && result.responseMessage) {
                if (
                  this.stateName === "CodeEntry" ||
                  this.stateName === "CodeDispatched"
                ) {
                  (this.labels.UserNotActivatedTitle =
                    result.response.NotificationTitle),
                    (this.labels.UserNotActivatedText =
                      result.response.NotificationContent);
                }
                if (this.stateName === "CodeExpired") {
                  (this.labels.UserNotActivatedTitle =
                    result.response.NotificationTitleState10),
                    (this.labels.UserNotActivatedText =
                      result.response.NotificationContentState10);
                }
                if (this.stateName === "SupportRequired") {
                  (this.labels.UserNotActivatedTitle =
                    result.response.NotificationTitleState84),
                    (this.labels.UserNotActivatedText =
                      result.response.NotificationContentState84);
                }
              }
            });
          } catch (e) {
            console.log("Error : " + e);
          }
        }
      });
    } catch (e) {
      console.log("Error : " + e);
    }
  },
  watch: {
    bookingList: {
      handler: function (newVal) {
        if (!this.serviceGroupCollection.length) {
          this.EmptyGraphWithoutServiceGroup();
          return;
        }
        if (!newVal.length) {
          this.EmptyAccountingGraph();
        } else {
          this.makeAccoundingGraph();
          this.SecondBoxresult();
        }
      },
    },
  },
  async beforeCreate() {    
    try {
      const Url = process.env.VUE_APP_BASE_URL + "/v1/pages/home";
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });

      if (response.data.responseCode === "200" && response.data.response) {
        this.contactInfo.location = response.data.response.ContactAddress;
        this.contactInfo.email = response.data.response.ContactEmailAddress;
        this.contactInfo.fax = response.data.response.ContactFaxNumber;
        this.contactInfo.phone = response.data.response.ContactPhoneNumber;
        this.labels.Introduction = response.data.response.Description;
        this.title = response.data.response.Title;
        this.Description = response.data.response.Description;
        this.AccountInfoContent.HeaderText =
          response.data.response.AccountInfoContent.HeaderText;
        this.AccountInfoContent.NoAccountInfoText =
          response.data.response.AccountInfoContent.NoAccountInfoText;
        this.AccountInfoContent.Content =
          response.data.response.AccountInfoContent.Content;
        this.AccountInfoContent.LinkText =
          response.data.response.AccountInfoContent.LinkText;
        this.AccountInfoContent.LinkTarget =
          response.data.response.AccountInfoContent.LinkTarget;

        this.MessageContent.HeaderText =
          response.data.response.MessageContent.HeaderText;
        this.MessageContent.NoMessageInfoText =
          response.data.response.MessageContent.NoMessageInfoText;
        this.MessageContent.Content =
          response.data.response.MessageContent.Content;
        this.MessageContent.LinkText =
          response.data.response.MessageContent.LinkText;
        this.MessageContent.LinkTarget =
          response.data.response.MessageContent.LinkTarget;

        this.AccountContent.HeaderText =
          response.data.response.AccountContent.HeaderText;
        this.AccountContent.Content =
          response.data.response.AccountContent.Content;
        this.AccountContent.LinkText =
          response.data.response.AccountContent.LinkText;
        this.AccountContent.LinkTarget =
          response.data.response.AccountContent.LinkTarget;

        this.FormsContent.HeaderText =
          response.data.response.FormsContent.HeaderText;
        this.FormsContent.Content = response.data.response.FormsContent.Content;
        this.FormsContent.LinkText =
          response.data.response.FormsContent.LinkText;
        this.FormsContent.LinkTarget =
          response.data.response.FormsContent.LinkTarget;

        this.ResidentContent.HeaderText =
          response.data.response.ResidentContent.HeaderText;
        this.ResidentContent.Content =
          response.data.response.ResidentContent.Content;
        this.ResidentContent.LinkText =
          response.data.response.ResidentContent.LinkText;
        this.ResidentContent.LinkTarget =
          response.data.response.ResidentContent.LinkTarget;
      }   
    } catch (e) {
      console.log("Error : " + e);
    }
  },
 
  mounted() {
    this.getBookings();
    this.getmessageCounts();
   this.$root.update_notifications();
    const fieldLabelData = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Accounting"
    );

    this.labels.Arrearage = fieldLabelData.Arrearage;
    this.labels.Deposit = fieldLabelData.Deposit;
    this.labels.Spent = fieldLabelData.Spent;

    const fieldLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "LocationData"
    );

    this.labels.CurrentAddressDashboard = fieldLabels.CurrentAddressDashboard;
    this.labels.PreviousAddressDashboard = fieldLabels.PreviousAddressDashboard;
    this.labels.NoAddressText = fieldLabels.NoAddressText;
    const fieldmessageLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Message"
    );
    
    this.labels.Read = fieldmessageLabels.Read;
    this.labels.Unread = fieldmessageLabels.Unread;
    this.labels.Archiv = fieldmessageLabels.Archiv;
    const fieldStartpageLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Startpage"
    );
    
    this.labels.ContactHeadline = fieldStartpageLabels.ContactHeadline;
    this.labels.SemiRegisteredAccountInfoText = fieldStartpageLabels.SemiRegisteredAccountInfoText;
    this.labels.SemiRegisteredMessageInfoText = fieldStartpageLabels.SemiRegisteredMessageInfoText;
    this.labels.SemiRegisteredAdressInfoText = fieldStartpageLabels.SemiRegisteredAdressInfoText;
  },

  methods: {
    ...mapActions({
      getBookings: "booking/getBooking",
      getmessageCounts: "message/getMessageCount",
    }),
    p(text) {
      return `<p> ${text} </p>`;
    },
    renderedContent(html) {
      // Remove underline from anchor tags
      html = html.replace(/<a\b([^>]*)>/gi, "<a $1>");
      html = html.replace(/<ul\b([^>]*)>/gi, '<ul $1  class="unorderedList">');
      html = html.replace(/<li\b([^>]*)>/gi, '<li $1  class="list">');
      html = html.replace(/##TOKEN##/g, this.$store.state.setting.token);

      // Replace other dynamic values if necessary

      return html;
    },

     makeAccoundingGraph() {
      var userStateName = Vue.vclGet('user_db_stateName','');
      if (this.stateName !== "Complete" && this.stateName !== undefined) {
        this.account.Sum = 0;
        this.EmptyGraphWithoutServiceGroup();
        return;
      }
      else if(this.stateName === "Complete" && this.stateName !== undefined){
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.account.Sum = 0;
      this.account.Balance = 0;
      let actualAmount = 0;
      let targetAmount = 0;
      var cc = 0;
      for (var i = 0; i < this.serviceGroupCollection.length; i++) {
        var sgItem = this.serviceGroupCollection[i];
        var sgBookings = this.get_bookings_by_service_group(sgItem.UUID);
        var bc = 0;
        var totalInvoiceAmount = 0;
        var totalActualAmount = 0;

        for (var j = 0; j < sgBookings.length; j++) {
          var bgItem = sgBookings[j];
          totalInvoiceAmount += bgItem.InvoiceAmount;
          totalActualAmount += bgItem.InvoiceAmount - bgItem.AmountPending;

          bc++;
        }

        targetAmount += totalInvoiceAmount;
        actualAmount += totalActualAmount;

        if (bc > 0) {
          this.chartData.labels.push(sgItem.Name);
          this.chartData.datasets[0].backgroundColor.push(sgItem.Color);
          this.chartData.datasets[0].data.push(totalInvoiceAmount);

          if (this.islegendCollection) {
            this.legendCollection.push({
              ID: cc,
              UUID: sgItem.UUID,
              Label: sgItem.Name,
              Icon: sgItem.Icon,
              Color: sgItem.Color,
              Sum: totalInvoiceAmount,
            });
          }
          cc++;
        }
      }

      this.account.Sum = targetAmount;
      this.account.Balance = targetAmount - actualAmount;

      this.chartData = {
        labels: this.chartData.labels,
        datasets: [
          {
            backgroundColor: this.chartData.datasets[0].backgroundColor,
            data: this.chartData.datasets[0].data,
          },
        ],
      };
      if (!targetAmount) {
        this.EmptyAccountingGraph();
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
      }
      else{
        this.stateName=userStateName;
        this.makeAccoundingGraph();
      }
    },
    EmptyAccountingGraph() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.legendCollection = [];
      var cc = 0;
      for (let serviceType of this.serviceGroupCollection) {
        var bc = 0;
        bc++;
        if (bc > 0) {
          this.chartData.labels.push(serviceType.Name);
          this.chartData.datasets[0].backgroundColor.push(serviceType.Color);
          this.chartData.datasets[0].data.push(0.00001);
          cc++;
        }
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
    },

    EmptyGraphWithoutServiceGroup() {
      this.chartData.labels = [];
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.datasets[0].data = [];
      this.legendCollection = [];
      var cc = 0;
      // eslint-disable-next-line
      for (let key of [1]) {
        var bc = 0;
        bc++;
        if (bc > 0) {
          this.chartData.labels.push("");
          this.chartData.datasets[0].backgroundColor.push("#808080");
          this.chartData.datasets[0].data.push(0.00001);
          cc++;
        }
      }
      this.updateChart();
      if (cc) {
        this.chartLoaded = true;
      }
    },
    updateChart() {
      this.$nextTick(() => {
        let self = this;
        self.$refs.booking_chart && this;
        self.$refs.booking_chart.update();
      });
    },

    SecondBoxresult() {
      this.thirdArray = [];
      let firstArray = this.$store.state.booking.serviceGroupCollections;
      let secondArray = this.$store.state.booking.bookingList;
      firstArray.forEach((item) => {
        let matchingItems = secondArray.filter(
          (secondItem) => secondItem.ServiceTypeGroup.Name === item.Name
        );
        if (matchingItems.length > 0) {
          let totalAmount = matchingItems.reduce(
            (sum, currentItem) => sum + currentItem.InvoiceAmount,
            0
          );

          let combinedItem = {
            Name: item.Name,
            Icon: item.Icon,
            Color: item.Color,
            amount: totalAmount,
          };

          this.thirdArray.push(combinedItem);
        }
      });
    },
    get_bookings_by_service_group: function (UUID) {
      return this.bookingList.filter(
        (obj) => obj.ServiceTypeGroup.UUID === UUID
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";
/* Default styling */
.chart-box {
  // width: 30%;
   position: relative;
    text-align: -webkit-center;
}
.booking-box {
  width: 100%;
}
.sli-img {
  margin-top: 5px;
}
.link:hover {
  color: black;
}
.unorderedList {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 10px;
}

.list {
  margin-bottom: 19px;
}
.para-class{
  line-height: 1;
}
.chart-text {
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cursor-class{
  cursor: auto;
}
.chart-text p {
  margin-bottom: 2px;
  line-height: 1.2rem;
  font-size: 16px;
}
.column {
  float: left;
  width: 33.33%; /* Use a third of the available width for each column */
  padding: 20px;
}
.content {
  position: relative;
}
.box {
  border: 1px solid #ebf0f4;
  background: white;
  border-top: 4px solid #ffde00;
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  margin-bottom: 20px;
  flex-grow: 1;
}
.box1 {

  display:flex;
  flex-direction:column;
  .content {
    flex:1;
  }
}
.box1link{
  flex:1;
}
.emptybox {
  border: 1px dashed #ebf0f4 !important;
  border-top: 4px solid #C7D0DB !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: auto;
}
.LinkText {
  justify-content: flex-end;
  padding-top:15px;
  font-weight: bold;
  
  a {
    color: #cf1748;
    text-decoration: none;
    font-size: 12px;
  }
  p{
    margin: 0;
  }
}
.redbox {
  padding: 0 2%;
  border-radius: 4px;
  background: #e621540d 0% 0% no-repeat padding-box;
  color: #d50000;
  background-color: rgb(255, 230, 230);
}
.LinkText1 {
  position: absolute;
  bottom: 2%;
  right: 0%;
}
.bold {
  font-size: 16px;
  font-weight: bold;
}
.head {
  font-size: 15px;
  font-weight: bold;
  line-height: 0.9;
  margin-bottom: 7px;
}
.tel {
  margin: 0;
}
.hight {
  height: 26px;
  width: 21px;
}
.b1 {
  position: absolute;
  right: 19px;
  bottom: -18px;
  width: 525px; /* Adjust the width as desired */
  height: 35px; /* Adjust the height as desired */
  background-color: #ffe6e6; /* Adjust the background color as desired */
}
.word {
  word-wrap: anywhere;
}

@media (max-width: 1013px) {
  .box {
    border: 1px solid #ebf0f4;
    border-top: 4px solid #ffde00;
    padding: 20px 18px 20px 18px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}
@media (max-width: 768px) {
  /* Adjust the values for smaller screens */
  .b1 {
    right: 10px;
    bottom: -10px;
    width: 100%;
    height: 30px;
  }
   .vcs-chart{
    display: flex;
    justify-content: center;
    width: 100%;
 }
 .booking-box{
    padding-left: 0 !important;
  }
  .chart-parent {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .chart-parent {
    flex-direction: column;
  }
  .chart-box {
    width: 100%;
  }
  .booking-box {
    width: 100%;
  }
  .rightitem {
    padding-left: 0px;
  }
  canvas#doughnut-chart {
    height: 240px !important;
    width: 240px !important;
  }
}
/* Media queries for mobile screens */
@media only screen and (max-width: 780px) {
  .column {
    width: 100%; /* Use full width for each column on mobile */
  }
  .box {
    height: auto;
  }
  .content {
    height: auto;
  }
  
  .gap{
    margin-top: 13px;
  }

  .redbox {
    margin-top: 5px;
  }

  

}

/* Media queries for tablet screens */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .column {
    width: 50%; /* Use half of the available width for each column on tablet */
  }

}


@media only screen and (min-width: 992px) and (max-width: 1016px) {
  .group{
    margin-right:5px ;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1439px) {
  
 
  .booking-box {
    width: 75%;
  }
  .category {
        min-width: fit-content;
  }
  
}
@media only screen and (min-width: 1700px) and (max-width: 3000px) {
  .gap{
    margin:0 auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .tel{
    width:max-content;
  }
}
@media only screen and (max-width: 991px) {
  .box {
    height: auto;
  }
}

/* Media queries for larger screens */
@media only screen and (min-width: 992px) {
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  .column {
    width: 33.33%; /* Use a third of the available width for each column on desktop */
  }
}
.vgs-info-box a.text-danger {
  color: $primaryColor !important;
}
.footer {
  margin: 0 auto;
  max-width: 700px;
  font-size: 16px;
}
.no-info {
  color: #5b6367;
}
p > a {
  color: $primaryColor;
  text-decoration: underline;
}
.mail-a {
  text-decoration: none !important;
}
.label-spent {
  font-size: 12px !important;
}
</style>