import { bookings } from '@/services/bookingService';
import _ from 'lodash';
import moment from 'moment';

export default {
  state: {
    bookingList: [],
    bookingCollection: [],
    bookingAddress: [],
    serviceGroupCollections: [],
    bookingFrom: moment().format('YYYY-MM-DD'),
    bookingTo: moment().format('YYYY-MM-DD'),
    serviceTypeGroupColor: {},
  },
  getters: {},
  actions: {
    async getBooking({ commit }) {
      bookings().then((res) => {
        commit('SET_BOOKING', res.response);
      });
    },
  },
  mutations: {
    SET_BOOKING_DATE(state, dateObj) {
      state.bookingFrom = moment(dateObj.from).format('YYYY-MM-DD');
      state.bookingTo = moment(dateObj.to).format('YYYY-MM-DD');
    },
    SET_LEGEND_BOOKING_LIST(state, item) {
      if (!item) {
        state.bookingList = state.bookingCollection;
        return;
      }
      state.bookingList = state.bookingCollection.filter((booking) => booking.ServiceTypeGroup.UUID === item.UUID);
    },
    SET_BOOKING_LIST(state, bookings) {
      for (let serviceType of state.serviceGroupCollections) {
        serviceType.Color = !bookings.length ? '#808080' : state.serviceTypeGroupColor[serviceType.UUID];
      }
      state.bookingList = bookings;
    },

    SET_DATE_BOOKING_LIST(state, bookings) {
      let From = state.bookingFrom.split('-');
      let To = state.bookingTo.split('-');
      let startDate = new Date(From[0], From[1] - 1, From[2]);
      let endDate = new Date(To[0], To[1] - 1, To[2]);

      let selectBookingArr = [];
      let bookingArr = [];
      let invoiceAmountTarget = 0;
      // let pendingAmountTarget = 0;
      let invoiceAmountActual = 0;
      let pendingAmountActual = 0;      
      let isParent = false;
      for (let parentBookingObj of bookings) {
        for (let i = 0; i < parentBookingObj.Bookings.length; i++) {
          let date = moment(parentBookingObj.Bookings[i].BookingDate)
            .format('YYYY-MM-DD')
            .split('-');
          let newbookingDate = new Date(date[0], date[1] - 1, date[2]);
          if (newbookingDate.getTime() <= endDate.getTime() && newbookingDate.getTime() >= startDate.getTime()) {
            bookingArr.push(parentBookingObj.Bookings[i]);
            if (parentBookingObj.Bookings[i].TargetActualTypeName === 'Target') {
              invoiceAmountTarget += parentBookingObj.Bookings[i].BookingInvoiceAmount;
              // pendingAmountTarget += parentBookingObj.Bookings[i].BookingPaidAmount;
            } else { // get Actual invoiceAmeount and pendingAmount to compare and calculate later the real pendingAmount
              invoiceAmountActual += parentBookingObj.Bookings[i].BookingInvoiceAmount;
              pendingAmountActual += parentBookingObj.Bookings[i].BookingPaidAmount;
            }
            isParent = true;
          } 
        }
        parentBookingObj.Bookings = bookingArr;
        bookingArr = [];
        if (isParent) {
          parentBookingObj.InvoiceAmount = invoiceAmountTarget;
          parentBookingObj.AmountPending = invoiceAmountActual - pendingAmountActual; // real pendingAmount is what invoiced Actual minus pending Acutal
          selectBookingArr.push(parentBookingObj);
          invoiceAmountTarget = 0;
          // pendingAmountTarget = 0;
          invoiceAmountActual = 0;
          pendingAmountActual = 0;                
        }
        isParent = false;
      }
      state.bookingList = selectBookingArr;
      for (let serviceType of state.serviceGroupCollections) {
        serviceType.Color = !state.bookingList.length ? '#808080' : state.serviceTypeGroupColor[serviceType.UUID];
      }
    },

    SET_BOOKING(state, bookings) {
      let uniqueBookAddressIdArr = [];
      let serviceTypeGroup = [];
      for (let booking of bookings) {
        if (
          booking.ServiceTypeGroup.Name !== '' &&
          booking.ServiceTypeGroup.UUID !== '00000000-0000-0000-0000-000000000000'
        ) {
          serviceTypeGroup.push(booking.ServiceTypeGroup);
          state.serviceTypeGroupColor[booking.ServiceTypeGroup.UUID] = booking.ServiceTypeGroup.Color;
        }
        booking.Bookings = booking.Bookings.sort(
          (a, b) => new moment(b.BookingDate).format('YYYYMMDD') - new moment(a.BookingDate).format('YYYYMMDD')
        );
        let bookingAddressArr = booking.Bookings.map((booking) => {
          // outcommented the 2 lines below because they were used to manually set paid Amount to zero (0), which is wrong for further calculation of pending Amount (see SET_DATE_BOOKING_LIST above)
          // let bookingRelationAmount = booking.BookingInvoiceAmount - booking.BookingPaidAmount;
          // booking.BookingPaidAmount = bookingRelationAmount < 0 ? 0 : bookingRelationAmount;
          return booking.Address;
        });
        let bookingArr = _.uniqBy(bookingAddressArr, 'AddressId');
        uniqueBookAddressIdArr.push(...new Set(bookingArr));
      }
      state.bookingAddress = _.uniqBy(uniqueBookAddressIdArr, 'AddressId');
      state.serviceGroupCollections = _.uniqBy(serviceTypeGroup, 'UUID');
      state.bookingList = bookings;
      state.bookingCollection = JSON.parse(JSON.stringify(bookings));
    },
  },
};
